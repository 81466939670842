import React, { useEffect, useState,useCallback,useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import HamburgerMenu from "../../components/shared/HamburgerMenu";
import LeftSideBar from "../../components/shared/LeftSidebar";
import RightSideBar from "../../components/shared/RightSidebar";
import InputComponent from "../../components/shared/Input/Index";
import { Form, Spinner } from "react-bootstrap";
import { errorMsgs, regexStrConst } from "../../constants/stringConstants";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { Dropdown } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { getAllAccounts } from "../../redux/toolkit/getAllUserAccount/operation";
import { AppDispatch, RootState } from "../../redux/store";
import { format } from 'date-fns';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { apiHelper } from '../../api helper/index'

import "./index.css";
import { useDispatch } from "react-redux";
import {
  GetInvoiceList,
  InvoiceNumber,
  PayexInvoice,
  GetInvoiceListPaginated
} from "../../redux/toolkit/verifyPayment/operation";
import InvoiceItem from "./InvoiceItem";
import InvoiceModal from "./preview";
import { getCodes, getCountry } from "iso-3166-1-alpha-2";
import { BsArrowRight } from "react-icons/bs";
import { ViewDocIcon ,DropDownArrowIcon} from "../../constants/Images";
import ModalHeader from "../../components/shared/Modal/ModalHeader";
import { DropDownArrowIconSolid, DropDownInputWhite } from "../../components/shared/DropdownSelect";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import BasicButton from "../../components/shared/Buttons/Basic";
import {TableFooter,TableBody,TableHead, Box, Skeleton} from '@mui/material';
import InputTypeText from "../../components/shared/Input/Basic/InputTypeText";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem'; 
import _ from 'lodash' 
import { useScreenSize } from "../../components/shared/ResizeHook";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";



// eslint-disable-next-line @typescript-eslint/no-var-requires
const currencySymbolMap = require("currency-symbol-map");
// import { getSymbolFromCurrency } from 'currency-symbol-map';

type CountryCodeDropdownProps = {
  onChange: (code: string) => void;
  value: string;
};

type itemList = {
  description: string;
  amount?: number;
  quantity: number;
  price: number;
  id: number;
};
// const recentOpPayload ={
//   userId:accountId,
//   page:1,
//   limit:5,
//   search:''
// } 

const Invoice = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [shouldBeEditable, setShouldBeEditable] = useState(false)
  const [searchText, setSearchText] = useState<any>("");


  const [CompRegNo, setCompRegNo] = useState("");
  const [CompRegNoError, setCompRegNoError] = useState("");


  const [accountType, setAccountType] = useState("");
  const [accountTypeError, setAccountTypeError] = useState("");

  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceDateError, setInvoiceDateError] = useState("");

  // Reason states
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEditPreview, setIsOpenEditPreview] = useState(false);

  const [invoiceReasonDes, setInvoiceReasonDes] = useState("");
  const [invoiceReasonDesError, setInvoiceReasonDesError] = useState("");
  // Items List
  const [listItemDescription, setListItemDescription] = useState("");
  const [listItemDesErrorMsg, setListItemDesErrorMsg] = useState("");

  const [listItemRate, setListItemRate] = useState("");
  const [listItemRateErrorMsg, setListItemRateErrorMsg] = useState("");

  const [listItemQauntity, setListItemQauntity] = useState("");
  const [listItemQauntityError, setListItemQauntityError] = useState("");

  const [itemError, setItemError] = useState("");

  const [invoiceAddress, setInvoiceAddress] = useState("");
  const [invoiceAddressError, setInvoiceAddressError] = useState("");

  const [addressee, setAddressee] = useState("");
  const [addresseeError, setAddresseeError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emptyEmailErrMsg, setEmailEmptyErrMsg] = useState("");


  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddressError, setStreetAddressError] = useState("");

  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");

  const [countryCode, setCountryCode] = useState("");
  const [countryCodeError, setCountryCodeError] = useState("");


  const [invoiceType, setInvoiceType] = useState("");
  const [invoiceTypeError, setInvoiceTypeError] = useState("");

  const [itemListError, setItemListError] = useState("");

  const [continueBtn, setContinueBtn] = useState(false);
  
  const [showAllInvoicesDropDown, setShowAllInvoicesDropDown] = useState(false);
  const [isShowAllInvoicesOpen, setIsShowAllInvoicesOpen] = useState(false);

  const countryCodes: string[] = getCodes();

  const seeAllInvoiceRef = useRef(null);

  const handleChange = (event: any) => {
    setCountryCodeError("");
    const selectedCode = event.target.value;
    setCountryCode(selectedCode);
  };

  const [totalAmount, setTotalAmount] = useState(1);
  const [subTotal, setSubTotal] = useState(1);
  const [tax, setTax] = useState(0.25);
  const [taxableAmount, setTaxableAmount] = useState(0);


  const [itemsList, setItemsList] = useState<itemList[]>([
    {
      description: "",
      price: 1.0,
      quantity: 1,
      id: 1,
    },
  ]);

  const [isChecked, setIsChecked] = useState(false);

  const accountId: any = localStorage.getItem("accountId");
  const [accUUID, setAccUUID] = useState(accountId);
  const [accountDetails, setAccountDetails] = useState<any>([]);
  const accountIdSelecto: any = useSelector((state: RootState) => state.getAllAccounts.data);
  const invoiceListSelector: any = useSelector((state: RootState) => state.invoice.data);
  const [invoicesState, setinvoices] = useState<[]>([]);
  const [showAll, setShowAll] = useState(false);

  const [userDetailPayex, setUserDetailPayex] = useState(null)
  const [currentPage, setCurrentPage] = useState<any>(invoiceListSelector?.result?.pagination?.currentPage || 1);


  const accData: any = localStorage.getItem("AccountData");
  const ParsedData: any = JSON.parse(accData);
  const [currency, setCurrency] = useState("");
  const [currencyError, setCurrencyError] = useState("");
  const [currencyIBAN, setCurrencyIBAN] = useState(ParsedData?.IBAN);
  const [accountInfo, setAccountInfo] = useState(ParsedData);
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(false);
  

  const accId: any = localStorage.getItem("activeAccId");
  const itemsAll = [
    {
      description: "",
      price: "1.00",
      quantity: 1,
    },
  ];
  const userId: any = localStorage.getItem("userId");
  const firstName: any = localStorage.getItem("firstName");
  const lastName: any = localStorage.getItem("lastName");

  const dispatch = useDispatch<AppDispatch>();
  const screenSize = useScreenSize();

  const openModal = (event: any) => {
    event.preventDefault();
    // this.handleCalculateTotal()
    if (validate()) {
      // if have any error
    } else {
      setContinueBtn(true);
      const formattedItemList: any = []
      itemsList.map((ele) => {
        formattedItemList.push({
          "itemDescription": ele.description,
          "amount": ele.price * ele.quantity,
          "quantity": ele.quantity,
          "unitPrice": ele.price
        })
      })
      const payload = {
        accountID: accUUID,
        // hardcore value for testing purpose of the swedish acconts
        // accountID: "e2ad7ee7-1e93-45fd-be48-abf3f9fec5b2",
        includeTax: isChecked,
        paymentType: accountType,
        invoiceDate: invoiceDate,
        invoiceAddress: {
          "addressee": addressee,
          "streetAddress": streetAddress,
          "zipCode": zipCode,
          "city": city,
          "countryCode": countryCode
        },
        invoiceReason: {
          invoiceReasonDescription: invoiceReasonDes
        },
        invoiceSpecification: {
          invoiceLines: formattedItemList
        },
      };
      console.log('payex api call payload is', payload)
      setIsOpen(true);
    }
  };

  const handleClosePR = () => {
    setShowAll(false);
  };

  const getUserDetailPayex = async () => {
    try {
      const userId = localStorage.getItem('userId')
      const token: any = localStorage.getItem('token')
      if(userId) {
        const res = await apiHelper(`user/getUser/${userId}`, "get",      
        {},
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        })
        if(res?.data?.data) 
          setUserDetailPayex(res?.data?.data)
      }
    } catch(error) {
      console.log('Error Occured for User Detail ', error)
    }
  }

  useEffect(() => {
    getUserDetailPayex()
  }, [])

  const generateInvoiceApi = () => {
    setLoading(true);
    const formattedItemList: any = [];
    itemsList.map((ele: any) => {
      formattedItemList.push({
        itemDescription: ele.description,
        amount: ele.price * ele.quantity,
        quantity: parseInt(ele.quantity),
        unitPrice: parseInt(ele.price),
        unit: "C62"
      });
    });

    const payload = {
      accountID: accUUID,
      // hardcore value for testing purpose of the swedish acconts
      // accountID: "e2ad7ee7-1e93-45fd-be48-abf3f9fec5b2",
      includeTax: isChecked,
      paymentType: accountType,
      invoiceDate: invoiceDate,
      newInvoiceNumber: invoiceNumber,
      invoiceAddress: {
        addressee: addressee,
        email: email,
        streetAddress: streetAddress,
        zipCode: zipCode,
        city: city,
        countryCode: countryCode,
        companyRegNo: CompRegNo,
      },
      invoiceReason: {
        invoiceReasonDescription: invoiceReasonDes,
      },
      invoiceSpecification: {
        invoiceLines: formattedItemList,
      },
    };
    dispatch(PayexInvoice(payload)).then((data: any) => {
      // resetInvoiceForm();
      setLoading(false);
      setContinueBtn(false);
      setShouldBeEditable(false);
    });
  };

  const generatePreview = (invoiceData: any) => {
    // setCurrencyIBAN(invoiceData.)
    setEmail(invoiceData.invoiceAddress.email);
    setAddressee(invoiceData.invoiceAddress.addressee);
    setStreetAddress(invoiceData.invoiceAddress.streetAddress);
    setCompRegNo(invoiceData.invoiceAddress.companyRegNo);
    setCity(invoiceData.invoiceAddress.city);
    setCountryCode(invoiceData.invoiceAddress.countryCode);
    setZipCode(invoiceData.invoiceAddress.zipCode);
    setInvoiceDate(invoiceData.invoiceDate);
    setInvoiceNumber(invoiceData.invoiceNo);
    setShowAllInvoicesDropDown(false)
    
    const currencySymbol = currencySymbolMap(
      invoiceData.currency
    );
    setCurrency(currencySymbol);
    setTotalAmount(parseFloat(invoiceData.legalMonetaryTotal.payableAmount.$numberDecimal));
    setSubTotal(parseFloat(invoiceData.invoiceSpecification.invoiceVatSpecification.vatSubTotal[0].taxableAmount.$numberDecimal));
    setTaxableAmount(parseFloat(invoiceData.invoiceSpecification.invoiceVatSpecification.vatSubTotal[0].vatAmount.$numberDecimal));
    const formattedItemList: any = [];
    invoiceData.invoiceSpecification.invoiceLines.map((ele: any) => {
      formattedItemList.push({
        description: ele.itemDescription,
        amount: parseFloat(ele.amount.$numberDecimal),
        quantity: parseFloat(ele.quantity.$numberDecimal),
        price: parseFloat(ele?.unitPrice?.$numberDecimal),
        unit: "C62"
      });
    });
    setItemsList(formattedItemList);
    const accountData = invoiceData.accountData || [];

    accountData.length > 0 ? setAccountInfo(accountData[0]) : console.log("No account data found for this invoice.");
    setIsOpenEditPreview(true);
    setIsOpen(true);
    if (showAll) {
      setIsShowAllInvoicesOpen(true);
      setShowAll(false);
    }
    // IBAN: currencyIBAN,
    // billedTo: addressee,
    // streetAddress: streetAddress,
    // compRegNo: CompRegNo,
    // city: city,
    // countryCode: countryCode,
    // zipCode: zipCode,
    // billedFrom: `${firstName} ${lastName}`,
    // dateOfIssue: invoiceDate,
    // invoiceNumber: invoiceNumber,
    // subTotal: subTotal,
    // taxableAmount: taxableAmount,
    // totalAmount: totalAmount,
    console.log("xxxxx", invoiceData);
    console.log('yyyy', formattedItemList);
  };

  const handleCheckboxChange = () => {
    setTotalAmount(subTotal * tax + subTotal);
    setTaxableAmount(subTotal * tax);
    setIsChecked(!isChecked);
  };

  const showTaxVal = () => {
    if (isChecked == false) {
      setTaxableAmount(0);
      setTotalAmount(subTotal);
    }
  }

  useEffect(() => {
    showTaxVal()
  }, [isChecked]);

  const resetInvoiceForm = () => {
    setEmail("");
    setStreetAddress("");
    setCompRegNo("");
    setAddressee("");
    setCity("");
    setZipCode("");
    setInvoiceDate("");
    setAccountInfo(ParsedData);
    dispatch(InvoiceNumber({ accountId })).then((data: any) => {
      console.log("Invoice number data apis is", data?.payload);
      setInvoiceNumber(data?.payload?.invoiceno);
      if(data?.payload?.invoiceno === '1')
        setShouldBeEditable(true)
    });
    setCurrency("");
    setTotalAmount(1);
    setSubTotal(1);
    setTaxableAmount(0);
    setItemsList([
      {
        description: "",
        price: 1.0,
        quantity: 1,
        id: 1,
      },
    ]);
    setIsOpenEditPreview(false);
    
      dispatch(GetInvoiceListPaginated({accountId:accId,page:1,limit:10,search:''}))
    // dispatch(GetInvoiceList(accId)).then((data: any) => {
    //   if(data?.payload?.data?.result)
    //     setinvoices(data?.payload?.data?.result);
    // });
  }

  const closeModal = (e: any) => {
    //when Edit Preview Modal is closed reset the form states
    // if (!isOpenEditPreview) {
    if (isOpenEditPreview) {
      resetInvoiceForm();
      // setEmail("");
      // setStreetAddress("");
      // setCompRegNo("");
      // setAddressee("");
      // setCity("");
      // // setCountryCode("");
      // // setInvoiceReasonDes("");
      // setZipCode("");
      // setInvoiceDate("");
      // setAccountInfo(ParsedData);
      // dispatch(InvoiceNumber({})).then((data: any) => {
      //   console.log("Invoice number data apis is", data?.payload);
      //   setInvoiceNumber(data?.payload?.invoiceno);
      // });
      // // const currencySymbol = currencySymbolMap(
      // //   invoiceData.currency
      // // );
      // setCurrency(currencySymbolMap(ParsedData?.currency));
      // setTotalAmount(1);
      // setSubTotal(1);
      // setTaxableAmount(0);
      // setItemsList([
      //   {
      //     description: "",
      //     price: 1.0,
      //     quantity: 1,
      //     id: 1,
      //   },
      // ]);
      // setIsOpenEditPreview(false);
      // dispatch(GetInvoiceList(accId)).then((data: any) => {
      //   if(data?.payload?.data?.result)
      //     setinvoices(data?.payload?.data?.result);
      // });
    } else {
      dispatch(InvoiceNumber({ accountId })).then((data: any) => {
        console.log("Invoice number data apis is", data?.payload);
        setInvoiceNumber(data?.payload?.invoiceno);
        if(data?.payload?.invoiceno === '1')
          setShouldBeEditable(true)
      });
      dispatch(GetInvoiceListPaginated({accountId:accId,page:1,limit:10,search:''}))

      // dispatch(GetInvoiceList(accId)).then((data: any) => {
      //   if(data?.payload?.data?.result)
      //     setinvoices(data?.payload?.data?.result);
      // });
    }
    setIsOpen(false);
    if (isShowAllInvoicesOpen) {
      setIsShowAllInvoicesOpen(false);
      setShowAll(true);
    }
  };

  useEffect(() => {
    dispatch(getAllAccounts(userId));
    dispatch(InvoiceNumber({ accountId })).then((data: any) => {
      console.log("Invoice number data apis is", data?.payload);
      setInvoiceNumber(data?.payload?.invoiceno);
      if(data?.payload?.invoiceno === '1')
        setShouldBeEditable(true)
    });
    // dispatch(GetInvoiceList(accId));
        dispatch(GetInvoiceListPaginated({accountId:accId,page:1,limit:10,search:''}))

    
  }, [dispatch]);

  useEffect(() => {
    if (accountIdSelecto) {
      // console.log("selected account data is =>", accountIdSelecto);
      setAccountDetails(accountIdSelecto?.data?.data);
    }
    if (invoiceListSelector) {

      setinvoices(invoiceListSelector?.result?.data);
      console.log("InvoicesState: =>", invoiceListSelector);
      // setloader(false);
    }
  }, [accountIdSelecto, accountDetails,invoiceListSelector,setSearchText]);

  useEffect(() => {
    if (itemsList.length > 0) {
      setItemError("");
    }
  }, [itemsList]);

  console.log("invoiceDate: ", invoiceDate);

  const validate = () => {
    console.log("account type", accountType);
    let check = false;
    if (accountType === "") {
      setAccountTypeError(errorMsgs.emptyAccountType);
      check = true;
    }
    if(currency === "") {
      setCurrencyError("errorMsgs.emptyCurrency");
      check = true;
    }
    if (invoiceDate === "") {
      setInvoiceDateError("errorMsgs.emptyDate");
      check = true;
    }
    if (invoiceReasonDes === "") {
      setInvoiceReasonDesError(errorMsgs.emptyIRDes);
      check = true;
    }
    if (addressee === "") {
      setAddresseeError(errorMsgs.emptyAddreesee);
      check = true;
    }
    if (email === "") {
      setEmailEmptyErrMsg(errorMsgs.emptyEmail);
      check = true;
    }

    if (invoiceType === "") {
      setInvoiceTypeError(errorMsgs.emptyInvoiceType);
      check = true;
    }


    if (email !== "" && emailError!=="") {
      check = true;
    }
    if (streetAddress === "") {
      setStreetAddressError(errorMsgs.emptyStreetAddress);
      check = true;
    }
    if (zipCode === "") {
      setZipCodeError(errorMsgs.emptyZipCode);
      check = true;
    }
    if (city === "") {
      setCityError(errorMsgs.emptyCity);
      check = true;
    }
    if (countryCode === "") {
      setCountryCodeError(errorMsgs.emptyCountryCode);
      check = true;
    }
    if (itemsList.length === 0) {
      setItemError(errorMsgs.emptyItemList);
      check = true;
    }

    if (CompRegNo === '') {
      setCompRegNoError(errorMsgs.emptyCompanyRegNo);
      check = true;
    }

    if (itemsList.length > 0) {
      setItemListError("");
      itemsList.map((ele) => {
        if (ele.description === "") {
          setItemListError(errorMsgs.itemListFieldsRequired);
          check = true;
        }
      });
    }
    return check;
  };

  const onChangeEmail = (event: any) => {
    const item = event.target.value;
    const regex = regexStrConst.emailRegex;
  
    setEmail(item.toLowerCase().trim());
  
    if (item === "") {
      setEmailEmptyErrMsg(errorMsgs.emptyEmail);
      setEmailError('');
    } else if (!regex.test(item)) {
      setEmailError(errorMsgs.incorrectEmail);
      setEmailEmptyErrMsg('');
    } else {
      setEmailError('');
      setEmailEmptyErrMsg('');
    }
  };

  const handleSubmit = (e?: any) => {
    e.preventDefault();
    if (validate()) {
      // if have any error
    } else {
      // api dispatch fuction goes here.
      setLoading(true);
      const payload = {
        accountID: accUUID,
        // hardcore value for testing purpose of the swedish acconts
        // accountID: "e2ad7ee7-1e93-45fd-be48-abf3f9fec5b2",
        includeTax: isChecked,
        paymentType: accountType,
        invoiceDate: invoiceDate,
        invoiceReason: {
          invoiceReasonDescription: invoiceReasonDes,
        },
        invoiceSpecification: {
          invoiceLines: itemsList,
        },
      };
      dispatch(PayexInvoice(payload)).then((data: any) => {
        setLoading(false);
        setInvoiceReasonDes("");
        setListItemDescription("");
        setTotalAmount(0);
        setItemsList([]);
        setLoading(false);
        setIsChecked(false);
      });
    }
  };
  const handleAddEvent = (e: any) => {
    const items = {
      description: "",
      price: 1.0,
      quantity: 1,
      id: itemsList[itemsList.length - 1].id + 1,
    };
    setItemsList([...itemsList, items]);
  };

  const showAllInvoices = () => {
    setShowAll(true)
    setShowAllInvoicesDropDown(false)
  }
  const getMaxDate = () => {
    const today = new Date();
    const futureDate = new Date(today.setMonth(today.getMonth() + 3));
    return futureDate.toISOString().split('T')[0];
  };
  const handleDueDateChange = (e:any) => {
    const selectedDate = e.target.value;
    const minDate = new Date('1900-01-01');
    const maxDate = new Date(getMaxDate());
    const selectedDateObj = new Date(selectedDate);

    if (selectedDateObj < minDate) {
      setInvoiceDateError('errorMsgs.startDatelimit');
    } else if (selectedDateObj > maxDate) {
      setInvoiceDateError('errorMsgs.dueDateLimit');
    } else {
      setInvoiceDateError('');
    }

    setInvoiceDate(selectedDate);
  };
 

  const onSearch = (text: string) => {
    setSearchText(text);
    debouncedSave(text);
    // await dispatch(GetInvoiceListPaginated({ accountId: accId, page: 1, limit: 10, search: text ? text : '' }))
     
  }
  const debouncedSave = useCallback(
    _.debounce(
      (value:string) =>{
        dispatch(GetInvoiceListPaginated({ accountId: accId, page: 1, limit: 10, search: value ? value.trim() : '' }))
        setCurrentPage(1)
      },
      600,
    ),
    [], // will be created only once initially
  );
 
  
  const handlePageChange = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    setIsLoadingSkeleton(true)
    await dispatch(GetInvoiceListPaginated({ accountId: accId, page: newPage, limit: 10, search: searchText ? searchText : '' }))
    setIsLoadingSkeleton(false)

  };
  const handlePaginationChange = (event:any, value:any) => {
    setCurrentPage(value); // Update the state with the selected page
    handlePageChange(event, value); // Pass the change to the parent handler
  };
  const onCloseInvoiceModal =()=>{
    dispatch(GetInvoiceListPaginated({ accountId: accId, page: 1, limit: 10, search: '' })),
    setSearchText('')
    setShowAll(false);


  }


  return (
    <>
    
    <div className="invoice">

    <Row>
      {/* <Col>
      </Col> */}
      <Col xs={12} sm={12} md={12} lg={8} xl={6} className="d-flex justify-content-end align-items-center ms-auto main-col-invoice">
        
        <div className="invoiceType col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">
        <FormControl className="top-selectors" sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    required={true}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                    onChange={(e: any) => {
                      setAccountType(e.target.value);
                      setAccountTypeError("");
                      setInvoiceType(e.target.value);
                      setInvoiceTypeError("");
                    }}
                  >
                  <option value="">{t("invoice.form.selectInvoiceType")}</option>
                  <option value="debit">{t("invoice.form.debit")}</option>
                  <option value="credit">{t("invoice.form.credit")}</option>

                  </NativeSelect>
                  {/* <span className="errorMsg">{emailError ? emailError : emptyEmailErrMsg ? emptyEmailErrMsg : ''}</span> */}
                  {/* <span className="errorMsg" style={{marginLeft:"5px"}}>{invoiceTypeError}</span> */}
                  </FormControl>
                  {/* <span>{accountTypeError}</span> */}
        {/* <Form.Group className="mb-3">
           <Form.Select
             aria-label="Default select example"
             onChange={(e: any) => {
               setAccountType(e.target.value);
               setAccountTypeError("");
             }}
           >
             <option value="">{t("invoice.form.selectInvoiceType")}</option>
             <option value="debit">{t("invoice.form.debit")}</option>
             <option value="credit">{t("invoice.form.credit")}</option>
           </Form.Select>
           <span className="errorMsg">{accountTypeError}</span>
         </Form.Group>  */}
        </div>
        
        <div className="currencyList col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">
        {/* <Form.Group className="mb-3">
           <Form.Select
             onChange={(e: any) => {
               console.log('currencyTargetVal: ', JSON.parse(e.target.value));
               localStorage.setItem("AccountData", e.target.value);
               const accountData = JSON.parse(e.target.value);
               console.log("accountData =>",accountData)
               const currencySymbol = currencySymbolMap(
                 accountData.currency
               );
               setAccountInfo(JSON.parse(e.target.value))
               setCurrency(currencySymbol);
               setAccUUID(accountData.accB4BUUID);
               setCurrencyIBAN(JSON.parse(e.target.value).IBAN);
             }}
            //  className="btn btn-light my-1"
             aria-label="Change Currency"
           >
             <option>
               {t("convert.form.selectedAcc")}: {ParsedData?.accountName}
             </option>
             {accountDetails &&
               accountDetails.map((ele: any, index: number) =>
                 !ele.inactive ? (
                   <option key={index} value={JSON.stringify(ele)}>
                     {ele.accountName}
                   </option>
                 ) : null
               )}
           </Form.Select>
         </Form.Group> */}
                <FormControl className="top-selectors" sx={{ width: "100%" }} variant="standard">
                  <NativeSelect
                    id="customized-select-native"
                    required={true}
                    input={<DropDownInputWhite />}
                    IconComponent={DropDownArrowIconSolid}
                    onChange={(e: any) => {
                      // localStorage.setItem("AccountData", e.target.value);
                      const accountData = JSON.parse(e.target.value);
                      const currencySymbol = currencySymbolMap(accountData.currency);
                      setAccountInfo(JSON.parse(e.target.value))
                      setCurrency(currencySymbol);
                      setCurrencyError("");
                      setAccUUID(accountData.accB4BUUID);
                      setCurrencyIBAN(JSON.parse(e.target.value).IBAN);
                    }}
                    sx={{
                      '.MuiNativeSelect-select': {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }
                    }}
                  >
                    <option hidden>
                    {t("invoice.form.currency")}
                    </option>
                    {/* <option disabled value={JSON.stringify(ParsedData)}>
                      {t("invoice.form.selectedCurrency")}: {ParsedData?.accountName}
                    </option> */}
                    {accountDetails &&
                      accountDetails.map((ele: any, index: number) =>
                        !ele.inactive ? (
                          <option key={index} value={JSON.stringify(ele)}>
                            {`${ele.currency} - ${ele.accountName}`}
                          </option>
                        ) : null
                      )}

                  </NativeSelect>
                  {/* <span className="errorMsg" style={{marginLeft:"5px"}}>{t(`${currencyError}`)}</span> */}
                </FormControl>
        </div>

        <div className="invoiceList col-3 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-1">
                  
                <div style={{ position: 'relative' }}>
                  <div className="listOFInvoicesBox" onClick={() => setShowAllInvoicesDropDown(!showAllInvoicesDropDown)}>
                    <p className="m-0">{invoiceNumber || t("invoice.righttitle")}</p>
                    {/* <img src={DropDownArrowIcon} /> */}
                  </div>
                  {/* Just un-comment the code below and the <img/> dropdown tag to see dropdown list of invoices and also modal is there where this list will be rendered */}
                  {/* <div className={`listOFInvoices ${showAllInvoicesDropDown ? 'showInvoicesDropDown' : 'hideInvoicesDropDown'}`} >
                    <ul>
                      {invoicesState && invoicesState.length > 0 ? (
                        invoicesState
                          .slice(0, 5)
                          // .reverse()
                          .map((ele: any, index: number) => {
                            return (
                              <>
                                <li className="text-primary my-2" style={{ cursor: "pointer" }}><a onClick={() => generatePreview(ele)} >{ele.invoiceNo}</a></li>
                              </>
                            );
                          })
                      ) 
                      : (
                        <div className="no-data-container">
                          <span className="no-recent-op" style={{ fontSize: '12px' }}>
                            {t("invoice.nodata")}
                          </span>
                        </div>
                      )}
                      {invoicesState && invoicesState.length > 0 && (
                        <li style={{ listStyle: "none" }}>  <div className="load-more-text-recent-div">
                          <span
                            className="load-more-text-recent"
                            onClick={showAllInvoices}
                          >
                            {t("recentOperation.seeAll")}
                            <BsArrowRight size={12} />
                          </span>
                        </div></li>
                      )}
                    </ul>
                  </div> */}
                </div>
        </div>

        
      </Col>
    </Row>
    <Row>
    <Col xs={12} sm={12} md={12} lg={8} xl={6} className="d-flex justify-content-end ms-auto main-col-invoice">
            <div className="invoiceType col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ps-1">
              <span className="errorMsg">{invoiceTypeError && t("errorMsgs.emptyInvoiceType")}</span>
            </div>
            <div className="currencyList col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ps-1">
              <span className="errorMsg">{t(`${currencyError}`)}</span>
            </div>
            <div className="invoiceList col-3 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
            </div>
          </Col>
    </Row>


      {/* <Col className="inner__contentInvoice p-2">
        <Row> 
            <Col>
                <Row className="justify-content-between align-items-center">
                  <Col>Invoice </Col>
                  <Col className="">Date</Col>
                </Row>  
            </Col>
        </Row>
      </Col> */}



      {/* <div className="hamburgerBackground">
        <HamburgerMenu name="invoice" />
      </div> */}
      {/* <div className="left_sidebar position-fixed">
        <LeftSideBar name="invoice" />
      </div> */}
      
      <div className="inner__contentInvoice mt-3 row p-1">
        {/* <h1 className="invoice_title invoice-heading">{t("invoice.title")}</h1> */}
        <div className="invoice-card-box">
          <div className="invoiceHeader-Section d-flex justify-content-between">

            <div className="invoiceDetails">
                <div className="invoiceHeading">
                  <h1 className="invoice_title invoice-heading">{t("invoice.title")}</h1>
                </div>
                <div className="invoiceNumber">
                <div className="d-flex flex-row align-items-center mb-1">
                   <span className=" me-2">
                     {t("invoice.form.invoiceNumber")} #
                   </span>
                   <Form.Control
                     className="input_style"
                     type="number"
                     
                     disabled={!shouldBeEditable}
                     value={invoiceNumber}
                     name={"invoiceNumber"}
                     onChange={(e) => {
                      //  console.log("test");
                      //  const pad = '0000000';
                      //  const invoiceNo = pad.substring(0, pad.length - e.target.value.length) + e.target.value;
                      //  setInvoiceNumber(invoiceNo)
                      if(e.target.value.length <= 7)
                              setInvoiceNumber(e.target.value)
                          }}
                     min="1"
                     required
                   />
                </div>
                </div>
            </div>

            <div className="invoiceDates">
              
             <div className="currentDate">
               <span className="">
                 {t("invoice.form.currentDate")}
               </span>
               <span style={{fontWeight:600}} className="current-date">
                 {new Date().toLocaleDateString()}
               </span>
             </div>

             <div className="d-flex flex-row align-items-center dueDate">
                 <span className="d-block me-2 text-nowrap">
                   {t("invoice.form.dueDate")}
                 </span>
                 <Form.Control
                   className="date_input_style"
                   type="date"
                  //  value={invoiceDate}
                   name={"dateOfIssue"}
                   max={getMaxDate()}
                  min="1900-01-01"
                  onBlur={handleDueDateChange}
                  //  onChange={(e: any) => {
                  //    setInvoiceDateError("");
                  //    setInvoiceDate(e.target.value);
                  //  }}
                   required
                 />
              </div>
                <span className="errorMsg">{t(`${invoiceDateError}`)}</span>
            </div>

          </div>



          <div className="borderTop">
            <Col md={12} sm={12} lg={12}>
              {/* <div className="p-4 p-xl-5 my-3 my-xl-4"> */}
              <div className="">
                
                {/* <hr className="my-4" /> */}
                <Row className="mb-2">
                   <Form.Label style={{fontWeight: 600}}>
                      {t("invoice.form.billTo.billToTitle")}
                    </Form.Label>
                     <Col xs={12} sm={12} md={10} lg={9} xl={9}>
                        <Row>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                           <Form.Control
                             placeholder={String(
                               t("invoice.form.invoiceAddress.name")
                               )}
                               value={addressee}
                               type="text"
                               name="Addressee"
                               className="billToInputs my-2"
                               maxLength={20}
                               onChange={(e: any) => {
                                 setAddresseeError("");
                                 setAddressee(e.target.value);
                               }}
                               autoComplete="address"
                               required
                               onBlur={() => {
                               addressee == ""
                              ? setAddresseeError(errorMsgs.emptyAddreesee)
                              : setAddresseeError("");
                                 }}
                           />
                          <span className="errorMsg">{addresseeError && t("errorMsgs.emptyAddreesee")}</span>

                          </Col>

                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Control
                            placeholder={String(
                            t("invoice.form.invoiceAddress.email")
                            )}
                            value={email}
                            type="text"
                            name="email"
                            className="billToInputs my-2"
                            maxLength={50}
                            autoComplete="email"
                            // required
                            onBlur={() =>
                           email === ""
                            // ? setEmailError(errorMsgs.emptyEmail)
                            // : !regexStrConst.emailRegex.test(email)
                            ? setEmailEmptyErrMsg(errorMsgs.emptyEmail)
                            : setEmailEmptyErrMsg("")
                            }
                            onChange={onChangeEmail}
                          />
                          <span className="errorMsg">{emailError ? t("errorMsgs.incorrectEmail") : emptyEmailErrMsg ? t("errorMsgs.emptyEmail") : ''}</span>
                          </Col>

                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Control
                            placeholder={String(
                              t("invoice.form.invoiceAddress.streetAddressLabel")
                            )}
                            value={streetAddress}
                            type="text"
                            name="Street Address"
                            className="billToInputs my-2"
                            maxLength={50}
                            onChange={(e: any) => {
                              setStreetAddressError("");
                              setStreetAddress(e.target.value);
                            }}
                            autoComplete="address"
                            required
                            onBlur={() => {
                              streetAddress == ""
                                ? setStreetAddressError(errorMsgs.emptyStreetAddress)
                                : setStreetAddressError("");
                            }}
                          />
                          <span className="errorMsg">{streetAddressError && t("errorMsgs.emptyStreetAddress")}</span>
                          </Col>
                          
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Control
                            placeholder={String(
                              t("invoice.form.invoiceAddress.cityLabel")
                            )}
                            value={city}
                            type="text"
                            name="City"
                            maxLength={20}
                            className="billToInputs my-2"
                            onChange={(e: any) => {
                              setCityError("");
                              setCity(e.target.value);
                            }}
                            autoComplete="name"
                            required
                            onBlur={() => {
                              city == ""
                                ? setCityError(errorMsgs.emptyCity)
                                : setCityError("");
                            }}
                          />
                          <span className="errorMsg">{cityError && t("errorMsgs.emptyCity")}</span>
                          </Col>

                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Control
                             placeholder={String(
                               t("invoice.form.invoiceAddress.zipCodeLabel")
                             )}
                             value={zipCode}
                             type="text"
                             name="ZipCode"
                             className="billToInputs my-2"
                             maxLength={20}
                             autoComplete="name"
                             onChange={(e: any) => {
                               setZipCodeError("");
                               setZipCode(e.target.value);
                             }}
                             required
                             onBlur={() => {
                               zipCode == ""
                                 ? setZipCodeError(errorMsgs.emptyZipCode)
                                 : setZipCodeError("");
                             }}
                           />
                           <span className="errorMsg">{zipCodeError && t("errorMsgs.emptyZipCode")}</span>
                          </Col>

                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Control
                             placeholder={String(
                               t("invoice.form.invoiceAddress.companyRegNo")
                             )}
                             type="text"
                             value={CompRegNo}
                             name="CompanyRegNo"
                             className="billToInputs my-2"
                             maxLength={30}
                             autoComplete="name"
                             aria-required
                             onChange={(e: any) => {
                               setCompRegNo(e.target.value);
                             }}
                             onBlur={() => {
                               CompRegNo == ""
                                 ? setCompRegNoError(errorMsgs.emptyCompanyRegNo)
                                 : setCompRegNoError("");
                             }}
                              />
                            <span className="errorMsg">{CompRegNoError && t("errorMsgs.emptyCompanyRegNo")}</span>
                          </Col>

                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Select
                            className="billToInputs mt-2"
                            aria-label="Default select example"
                            onChange={handleChange}
                          >
                            <option value="">{t("invoice.form.invoiceAddress.selectCountryCodeLabel")}</option>
                            {countryCodes.map((code) => (
                              <option key={code} value={code}>
                                {`${getCountry(code)} (${code})`}
                              </option>
                            ))}
                          </Form.Select>
                          <span className="errorMsg">{countryCodeError && t("errorMsgs.emptyCountryCode")}</span>
                          </Col>

                        </Row>

                        <Row className="mt-4">
                          <Col xs={12} sm={12} md={10} lg={6} xl={6}>
                          <Form.Group className="mb-3">
                           <Form.Label style={{fontWeight: 600}}>
                             {t("invoice.form.invoiceReason.invoiceReasonTitle")}
                           </Form.Label>

                           <Form.Select
                             className="billToInputs my-1"
                             aria-label="Default select example"
                             onChange={(e: any) => {
                               setInvoiceReasonDesError("");
                               setInvoiceReasonDes(e.target.value);
                             }}
                           >
                             <option value="">{t("invoice.form.invoiceReason.invoiceReasonSelectType")}</option>
                             <option value="saleOfGoods">{t("invoice.form.invoiceReason.invoiceReasonSelectTypeItem1")}</option>
                             <option value="saleOfServices">{t("invoice.form.invoiceReason.invoiceReasonSelectTypeItem2")}</option>
                           </Form.Select>
                           <span className="errorMsg">{invoiceReasonDesError && t("errorMsgs.emptyIRDes")}</span>

                           {/* ================================================= */}

                          </Form.Group>
                          </Col>
                        </Row>
                     </Col>

                  {/* <Col
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    className="d-flex flex-column"
                  >
                    <Form.Label className="fw-bold">
                      {t("invoice.form.billTo.billToTitle")}
                    </Form.Label>
                    <Form.Control
                      placeholder={String(
                        t("invoice.form.invoiceAddress.name")
                      )}
                      value={addressee}
                      type="text"
                      name="Addressee"
                      className="my-2"
                      maxLength={20}
                      onChange={(e: any) => {
                        setAddresseeError("");
                        setAddressee(e.target.value);
                      }}
                      autoComplete="address"
                      required
                      onBlur={() => {
                        addressee == ""
                          ? setAddresseeError(errorMsgs.emptyAddreesee)
                          : setAddresseeError("");
                      }}
                    />
                    <span className="errorMsg">{addresseeError}</span>

                    <Form.Control
                      placeholder={String(
                        t("invoice.form.invoiceAddress.email")
                      )}
                      value={email}
                      type="text"
                      name="email"
                      className="my-2"
                      maxLength={50}
                      autoComplete="email"
                      // required
                      onBlur={() =>
                        email === ""
                          // ? setEmailError(errorMsgs.emptyEmail)
                          // : !regexStrConst.emailRegex.test(email)
                          ? setEmailEmptyErrMsg(errorMsgs.emptyEmail)
                          : setEmailEmptyErrMsg("")
                      }
                      onChange={onChangeEmail}
                    />
                    <span className="errorMsg">{emailError ? emailError : emptyEmailErrMsg ? emptyEmailErrMsg : ''}</span>

                    <Form.Control
                      placeholder={String(
                        t("invoice.form.invoiceAddress.streetAddressLabel")
                      )}
                      value={streetAddress}
                      type="text"
                      name="Street Address"
                      className="my-2"
                      maxLength={50}
                      onChange={(e: any) => {
                        setStreetAddressError("");
                        setStreetAddress(e.target.value);
                      }}
                      autoComplete="address"
                      required
                      onBlur={() => {
                        streetAddress == ""
                          ? setStreetAddressError(errorMsgs.emptyStreetAddress)
                          : setStreetAddressError("");
                      }}
                    />
                    <span className="errorMsg">{streetAddressError}</span>

                    <Form.Control
                      placeholder={String(
                        t("invoice.form.invoiceAddress.cityLabel")
                      )}
                      value={city}
                      type="text"
                      name="City"
                      maxLength={20}
                      className="my-2"
                      onChange={(e: any) => {
                        setCityError("");
                        setCity(e.target.value);
                      }}
                      autoComplete="name"
                      required
                      onBlur={() => {
                        city == ""
                          ? setCityError(errorMsgs.emptyCity)
                          : setCityError("");
                      }}
                    />
                    <span className="errorMsg">{cityError}</span>

                    <Form.Control
                      placeholder={String(
                        t("invoice.form.invoiceAddress.zipCodeLabel")
                      )}
                      value={zipCode}
                      type="text"
                      name="ZipCode"
                      className="my-2"
                      maxLength={20}
                      autoComplete="name"
                      onChange={(e: any) => {
                        setZipCodeError("");
                        setZipCode(e.target.value);
                      }}
                      required
                      onBlur={() => {
                        zipCode == ""
                          ? setZipCodeError(errorMsgs.emptyZipCode)
                          : setZipCodeError("");
                      }}
                    />
                    <span className="errorMsg">{zipCodeError}</span>

                    <Form.Control
                      placeholder={String(
                        t("invoice.form.invoiceAddress.companyRegNo")
                      )}
                      type="text"
                      value={CompRegNo}
                      name="CompanyRegNo"
                      className="my-2"
                      maxLength={30}
                      autoComplete="name"
                      aria-required
                      onChange={(e: any) => {
                        setCompRegNo(e.target.value);
                      }}
                      onBlur={() => {
                        CompRegNo == ""
                          ? setCompRegNoError(errorMsgs.emptyCompanyRegNo)
                          : setCompRegNoError("");
                      }}
                    />
                    <span className="errorMsg">{CompRegNoError}</span>


                    <Form.Select
                      className="btn btn-light my-1"
                      aria-label="Default select example"
                      onChange={handleChange}
                    >
                      <option value="">{t("invoice.form.invoiceAddress.selectCountryCodeLabel")}</option>
                      {countryCodes.map((code) => (
                        <option key={code} value={code}>
                          {`${getCountry(code)} (${code})`}
                        </option>
                      ))}
                    </Form.Select>
                    <span className="errorMsg">{countryCodeError}</span>
                  </Col> */}
                </Row>
               
                {/* <hr className="my-4" style={{color:'#8a8e92'}}/> */}

                {/*Include VAT  */}
                {/* <div>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        position: "relative",
                        width: "40px",
                        height: "20px",
                        borderRadius: "20px",
                        backgroundColor: isChecked ? "#00bcd4" : "#ccc",
                        transition: "background-color 0.2s ease-in-out",
                        cursor: "pointer",
                      }}
                      onClick={handleCheckboxChange}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          position: "absolute",
                          top: "2px",
                          left: isChecked ? "20px" : "2px",
                          width: "16px",
                          height: "16px",
                          borderRadius: "50%",
                          backgroundColor: "#fff",
                          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.4)",
                          transition: "left 0.2s ease-in-out",
                        }}
                      ></div>
                    </div>
                    <span style={{ marginLeft: "10px" }}>
                      {t("invoice.form.includeTax")}
                    </span>
                  </label>
                </div> */}


                <InvoiceItem
                  onItemizedItemEdit={(e: any) => {
                    if (e.target.name === "quantity" && e.target.value.length > 10) return;
                    e.target.name === "description" && e.target.value.length > 0 ? setItemListError("") : ""
                    const newItems = itemsList.map((ele) => {
                      if (ele.id == e.target.id) {
                        return {
                          ...ele,
                          description:
                            e.target.name === "description"
                              ? e.target.value
                              : ele.description,
                          quantity:
                            e.target.name === "quantity"
                              ? e.target.value
                              : ele.quantity,
                          price:
                            e.target.name === "price"
                              ? e.target.value.replace(/\D/g, '')
                              : ele.price,
                        };
                      } else {
                        return {
                          ...ele,
                        };
                      }
                    });
                    let subAmount = 0;
                    let totalAmount = 0;
                    newItems.map((ele) => {
                      subAmount += ele.price * ele.quantity;
                    });
                    setSubTotal(subAmount);
                    if (isChecked) {
                      totalAmount = subAmount * tax + subAmount;
                      setTaxableAmount(subAmount * tax);
                      setTotalAmount(totalAmount);
                    } else {
                      setTotalAmount(subAmount);
                    }
                    setItemsList(newItems);
                  }}
                  onRowAdd={(e: any) => {
                    handleAddEvent(e);
                  }}
                  onRowDel={(item: any) => {
                    if (itemsList.length === 1) {
                      setItemsList(
                        itemsList.map((ele) => ({
                          ...ele,
                          description: "",
                          price: 1.0,
                          quantity: 1,
                        }))
                      );
                    } else {
                      setItemsList(itemsList.filter((e) => e.id !== item?.id));
                    }
                  }}
                  currency={currency}
                  items={itemsList}
                  creditFormat={invoiceReasonDes}
                />
                <span className="errorMsg">{itemListError && t("errorMsgs.itemListFieldsRequired")}</span>
                <Row className="mt-4 justify-content-end">
                  




                  <Col lg={6}>
                    <div>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "600",
                      marginBottom: "10px",
                      justifyContent:"space-between"
                    }}
                  >
                    <span style={{ marginRight: "10px" }}>
                      {t("invoice.form.includeTax")}
                    </span>
                    <div
                      style={{
                        display: "inline-block",
                        position: "relative",
                        width: "40px",
                        height: "20px",
                        borderRadius: "20px",
                        backgroundColor: isChecked ? "#00bcd4" : "#ccc",
                        transition: "background-color 0.2s ease-in-out",
                        cursor: "pointer",
                      }}
                      onClick={handleCheckboxChange}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          position: "absolute",
                          top: "2px",
                          left: isChecked ? "20px" : "2px",
                          width: "16px",
                          height: "16px",
                          borderRadius: "50%",
                          backgroundColor: "#fff",
                          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.4)",
                          transition: "left 0.2s ease-in-out",
                        }}
                      ></div>
                    </div>
                    
                  </label>
                     </div>

                    <div className="d-flex flex-row align-items-start justify-content-between mb-2">
                      <span style={{fontWeight: 600}}>IBAN:</span>
                      <span className="totalValue">{currencyIBAN && currencyIBAN} </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between">
                      <span style={{fontWeight: 600}}>Subtotal:</span>
                      <span className="totalValue">
                      {accountType === "credit" ? '-' : ''} {currency} {subTotal}
                      </span>
                    </div>
                    {/* <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span className="fw-bold">Discount:</span>
                      <span>
                        <span className="small ">( 0%)</span>
                        $
                        {0}</span>
                    </div> */}
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span style={{fontWeight: 600}}>{t("invoice.form.itemsListTotal.tax")}:</span>
                      <span className="totalValue">
                        <span className="small ">
                          ( {isChecked ? tax * 100 : 0}% )
                        </span>{" "}
                        {currency} {taxableAmount}
                      </span>
                    </div>
                    <hr />
                    <div
                      className="d-flex flex-row align-items-start justify-content-between"
                      style={{
                        fontSize: "1.125rem",
                      }}
                    >
                      <span style={{color:'#006FF4', fontWeight: 600}}>Total:</span>
                      <span style={{color:'#006FF4', fontWeight: 600}}>
                      {accountType === "credit" ? '-' : ''} {currency} {totalAmount}
                      </span>
                    </div>
                  </Col>
                </Row>
                {/* <Form.Label className="fw-bold">Notes:</Form.Label>
          <Form.Control placeholder="Thanks for your business!" name="notes" value={notes} onChange={() => {console.log("test")}} as="textarea" className="my-2" rows={1} /> */}
              </div>
            </Col>


            {/* left Section */}
            <Col sm={12} md={12} lg={12} xl={12} className="mt-5 d-flex justify-content-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <BasicButton text={t("invoice.form.reviewInvoiceBtn")} type="submit" onClick={openModal}/> 

                {/* <Button
                style={{width:'100%'}}
                  variant="primary"
                  type="submit"
                  onClick={openModal}
                  // className="d-block w-100"
                >
                  {t("invoice.form.reviewInvoiceBtn")}
                </Button> */}

              </div>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} className="mt-5">
            <div style={{borderBottom:'1px solid lightgrey', marginBottom:"30px",overflowX:'auto'}} className="invoiceListModal">
          <InputTypeText
          
                placeholder={"Search"}
                  value={searchText}
                  onChange={(e) => { onSearch(e.target.value) }}
                  // defaultValue=""
                  className=""
                />
            <table className="table p-4 invoice-list-table">
              <thead>
                <tr>
                  <td className="amount-op-text">Invoice No.</td>
                  <td className="amount-op-text">Invoice Date</td>
                  <td className="amount-op-text">Due Date</td>
                  <td className="amount-op-text">Address</td>
                  <td className="amount-op-text">Name</td>
                  <td className="amount-op-text">Preview</td>
                </tr>
              </thead>
              <tbody>
                {invoicesState && invoicesState.length > 0 && (
                  invoicesState
                    .slice(0)
                    .map((ele: any, index: number) => {
                      return (
                        <>
                          {!isLoadingSkeleton && <tr key={index} style={{ borderBottom: '#fff' }}>
                            <td
                              // colSpan={2}
                              className="newClass"
                              style={{
                                maxWidth: 245,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele.invoiceNo}
                            </td>
                            <td
                              className=""
                              style={{
                                maxWidth: 400,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {format(new Date(ele.createdAt), 'yyyy-MM-dd')}
                            </td>
                            <td
                              style={{
                                maxWidth: 180,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {format(new Date(ele.invoiceDate), 'yyyy-MM-dd')}
                            </td>
                            <td
                              className=""
                              style={{
                                maxWidth: 150,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele?.invoiceAddress?.city}, {ele?.invoiceAddress?.zipCode}, {ele?.invoiceAddress?.countryCode}
                            </td>
                            <td
                              className=""
                              style={{
                                maxWidth: 100,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele?.invoiceAddress?.addressee}
                            </td>
                            <td className="d-flex justify-content-center">
                              <img className="preview-icon" src={ViewDocIcon} onClick={() => generatePreview(ele)} />
                            </td>
                          </tr>
                          }
                          {isLoadingSkeleton && <tr> {/* Adjust row height */}
                            <td className="amount-op-text newClass" style={{ padding: "5px" }}> {/* Adjust cell padding */}
                              <Box sx={{ width: "100%" }}>
                                <Skeleton animation="wave" sx={{ height: "13px" }} /> {/* Adjust Skeleton height */}
                                <Skeleton animation={false} sx={{ height: "13px" }} />
                              </Box>
                            </td>
                            <td className="negative-op-text-new" style={{ padding: "5px" }}>
                              <Box sx={{ width: "100%" }}>
                                <Skeleton animation="wave" sx={{ height: "13px" }} />
                                <Skeleton animation={false} sx={{ height: "13px" }} />
                              </Box>
                            </td>
                            <td className="negative-op-text-new" style={{ padding: "5px" }}>
                              <Box sx={{ width: "100%" }}>
                                <Skeleton animation="wave" sx={{ height: "13px" }} />
                                <Skeleton animation={false} sx={{ height: "13px" }} />
                              </Box>
                            </td>
                            <td className="negative-op-text-new" style={{ padding: "5px" }}>
                              <Box sx={{ width: "100%" }}>
                                <Skeleton animation="wave" sx={{ height: "13px" }} />
                                <Skeleton animation={false} sx={{ height: "13px" }} />
                              </Box>
                            </td>
                            <td style={{ padding: "5px" }}>
                              <Box sx={{ width: "100%" }}>
                                <Skeleton animation="wave" sx={{ height: "13px" }} />
                                <Skeleton animation={false} sx={{ height: "13px" }} />
                              </Box>
                            </td>
                            <td style={{ padding: "5px" }}>
                              <Box sx={{ width: "100%" }}>
                                <Skeleton animation="wave" sx={{ height: "13px" }} />
                                <Skeleton animation={false} sx={{ height: "13px" }} />
                              </Box>
                            </td>
                          </tr>}
                          
                        </>
                      );
                    })
                )}

              </tbody>
              {!invoicesState || !invoicesState.length && <div className="no-data-container w-100 text-center pt-5" style={{display:'table-caption'}}>
                <span className="no-recent-op">
                  {t("paymentReceiver.noData")}
                </span>
              </div>}
            </table>
          </div>
          <Pagination
        sx={{
          // marginTop: "10px"
          width:'100%',
          '& ul': { justifyContent:'center' },
        }}
        color="primary"
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        size={screenSize.width <= 576  ? "small" : "medium"}
        count={Math.ceil(invoiceListSelector?.result?.pagination?.pages * 10 / invoiceListSelector?.result?.pagination?.pageSize)} // pagesize is rows per page
        // page={invoiceListSelector?.result?.pagination?.currentPage}
        page={currentPage} // Changed: Sync current page with state
      onChange={handlePaginationChange} // Changed: Use local handler for page changes
        renderItem={(item) => (
          <PaginationItem
            {...item}
            component="div"
            sx={{
              fontFamily: 'Montserrat',
              borderRadius: '6px !important',
              // '&:hover:not(:active)': { backgroundColor: '#006FF4', color: '#FFFFFF' },
              '&:hover': { backgroundColor: '#006FF4', color: '#FFFFFF' },
              "&.Mui-selected": {
                backgroundColor: "#006FF4 !important",
                color: "white",
                borderColor: "transparent",
                borderRadius: "6px"
              }

            }}
            slots={{
              previous: () => screenSize.width <= 768
                ? <GrFormPrevious width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.previous")}</p>,
              next: () => screenSize.width <= 768
                ? <GrFormNext width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.next")}</p>,
            }}
          />
        )}
      />
            </Col>
            
            <Col md={4} lg={3}>
              <div className="sticky-top pt-md-3 pt-xl-4">
                <InvoiceModal
                  showModal={isOpen}
                  // showModal={true}
                  closeModal={closeModal}
                  continueBtn={continueBtn}
                  info={{
                    IBAN: currencyIBAN,
                    email: email,
                    accountInfo,
                    billedTo: addressee,
                    streetAddress: streetAddress,
                    compRegNo: CompRegNo,
                    city: city,
                    countryCode: countryCode,
                    zipCode: zipCode,
                    billedFrom: `${firstName} ${lastName}`,
                    dateOfIssue: invoiceDate,
                    invoiceNumber: invoiceNumber,
                    subTotal: subTotal,
                    taxableAmount: taxableAmount,
                    totalAmount: totalAmount,
                    userDetailPayex: userDetailPayex,
                    accountType:accountType
                  }}
                  isOpenEditPreview={isOpenEditPreview}
                  items={itemsList}
                  currency={currency}
                  onGenerateInvoice={generateInvoiceApi}
                  generateInvoiceLoading={loading}
                />
                {/* Invoice TypeDropDown
                
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">
                    {t("invoice.form.type")}
                  </Form.Label>
                  <Form.Select
                    className="btn btn-light my-1"
                    aria-label="Default select example"
                    onChange={(e: any) => {
                      setAccountType(e.target.value);
                      setAccountTypeError("");
                    }}
                  >
                    <option value="">{t("invoice.form.selectInvoiceType")}</option>
                    <option value="debit">{t("invoice.form.debit")}</option>
                    <option value="credit">{t("invoice.form.credit")}</option>
                  </Form.Select>
                  <span className="errorMsg">{accountTypeError}</span>
                </Form.Group> */}

                {/* 
                Currency DropDown
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">{t("invoice.form.currency")}:</Form.Label>
                  <Form.Select
                    onChange={(e: any) => {
                      console.log('currencyTargetVal: ', JSON.parse(e.target.value));
                      localStorage.setItem("AccountData", e.target.value);
                      const accountData = JSON.parse(e.target.value);
                      console.log("accountData =>",accountData)
                      const currencySymbol = currencySymbolMap(
                        accountData.currency
                      );
                      setAccountInfo(JSON.parse(e.target.value))
                      setCurrency(currencySymbol);
                      setAccUUID(accountData.accB4BUUID);
                      setCurrencyIBAN(JSON.parse(e.target.value).IBAN);
                    }}
                    className="btn btn-light my-1"
                    aria-label="Change Currency"
                  >
                    <option>
                      {t("convert.form.selectedAcc")}: {ParsedData?.accountName}
                    </option>
                    {accountDetails &&
                      accountDetails.map((ele: any, index: number) =>
                        !ele.inactive ? (
                          <option key={index} value={JSON.stringify(ele)}>
                            {ele.accountName}
                          </option>
                        ) : null
                      )}
                  </Form.Select>
                </Form.Group> */}

                {/* <Form.Group className="my-3">
                  <Form.Label className="fw-bold">Tax rate:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control name="taxRate" type="number" value={0} onChange={() => { console.log("test") }} className="bg-white border" placeholder="0.0" min="0.00" step="0.01" max="100.00" />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">Discount rate:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control name="discountRate" type="number" value={0} onChange={() => { console.log("test") }} className="bg-white border" placeholder="0.0" min="0.00" step="0.01" max="100.00" />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group> */}

                {/* <div>
                  <Form.Label className="fw-bold">
                    {t("invoice.righttitle")}
                  </Form.Label>
                  <ul style={{ padding: 0 }}>
                    {invoicesState && invoicesState.length > 0 ? (
                      invoicesState
                        .slice(-5)
                        .reverse()
                        .map((ele: any, index: number) => {
                          return (
                            <>
                              <li className="text-primary" style={{ cursor: "pointer" }}><a onClick={() => generatePreview(ele)} >Invoice # {ele.invoiceNo}</a></li>
                            </>
                          );
                        })
                    ) : (
                      <div className="no-data-container">
                        <span className="no-recent-op">
                          {t("invoice.nodata")}
                        </span>
                      </div>
                    )}
                    {invoicesState && invoicesState.length > 0 && (
                      <li style={{ listStyle: "none" }}>  <div className="load-more-text-recent-div">
                        <span
                          className="load-more-text-recent"
                          onClick={() => setShowAll(true)}
                        >
                          {t("recentOperation.seeAll")}
                          <BsArrowRight size={12} />
                        </span>
                      </div></li>
                    )}
                  </ul>

                </div> */}
              </div>
            </Col>
            {/* left Section */}

          </div>
        </div>
      </div>

      {/* Invoice List Modal */}
      <Modal
        // style={{ marginTop: 100, maxHeight: "75%" }}
        show={showAll}
        onHide={handleClosePR}
        backdrop="static"
        keyboard={false}
        width={"100%"}
        scrollable
        size="lg"
        className="invoiceListModal"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>{t("invoice.seeAllModal.title")}</Modal.Title>
        </Modal.Header> */}
        <ModalHeader title={t("invoice.seeAllModal.title")} onClose={onCloseInvoiceModal} modalBodyRef={seeAllInvoiceRef}/>
        <Modal.Body ref={seeAllInvoiceRef}>
          <div style={{width:"100%"}}>
          <InputTypeText
          
                placeholder={"Search"}
                  value={searchText}
                  onChange={(e) => { onSearch(e.target.value) }}
                  // defaultValue=""
                  className=""
                />
            <table className="table p-4 invoice-list-table">
              <thead>
                <tr>
                  <td className="amount-op-text">Invoice No.</td>
                  <td className="amount-op-text">Invoice Date</td>
                  <td className="amount-op-text">Due Date</td>
                  <td className="amount-op-text">Address</td>
                  <td className="amount-op-text">Name</td>
                  <td className="amount-op-text">Preview</td>
                </tr>
              </thead>
              <tbody>
                {invoicesState && invoicesState.length > 0 && (
                  invoicesState
                    .slice(0)
                    // .reverse()
                    .map((ele: any, index: number) => {
                      return (
                        <>
                          <tr key={index}>
                            <td
                              // colSpan={2}
                              className="newClass"
                              style={{
                                maxWidth: 245,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele.invoiceNo}
                            </td>
                            {/* <td
                              className="negative-op-text-new"
                              style={{
                                textAlign: "center",
                                maxWidth: 300,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
{ele.name}
                            </td> */}
                            <td
                              className=""
                              style={{
                                maxWidth: 400,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {format(new Date(ele.createdAt), 'yyyy-MM-dd')}
                            </td>
                            <td
                              style={{
                                maxWidth: 180,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {format(new Date(ele.invoiceDate), 'yyyy-MM-dd')}
                            </td>
                            <td
                              className=""
                              style={{
                                maxWidth: 150,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele?.invoiceAddress?.city}, {ele?.invoiceAddress?.zipCode}, {ele?.invoiceAddress?.countryCode}
                            </td>
                            <td
                              className=""
                              style={{
                                maxWidth: 100,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele?.invoiceAddress?.addressee}
                            </td>
                            <td className="d-flex justify-content-center">
                              {/* <div className="dropdown text-end dropup">
                                <BsThreeDotsVertical
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  className="three-dot-op dropdown-toggle"
                                  onClick={() => ""}
                                  size={20}
                                />
                                <ul className="dropdown-menu">
                                  <li>
                                    <Button
                                      className="dropdown-item"
                                      onClick={()=>deleteBeneficiary(ele._id)}
                                    >
                                      {t(
                                       "paymentReceiver.seeAllModal.button.delete"
                                        // "recentOperation.seeAllModal.button.view"
                                      )}
                                    </Button>
                                  </li>
                                  <hr />
                                  <li>
                                    <Button
                                      className="dropdown-item"
                                      onClick={() => {
                                        "";
                                      }}
                                    >
                                      {t(
                                         "paymentReceiver.seeAllModal.button.edit"
                                      )}
                                    </Button>
                                  </li>
                                </ul>
                              </div> */}
                              {/* <a style={{cursor:"pointer",display:"flex",width:"100%",justifyContent:"center"}} onClick={() => generatePreview(ele)} ><FileOpenIcon fontSize="small" color="primary" /><span style={{ color: '#1976d2' }}></span></a> */}
                              <img className="preview-icon" src={ViewDocIcon}  onClick={() => generatePreview(ele)}/>
                            </td>
                          </tr>
                        </>
                      );
                    })
                )}

              </tbody>
              {!invoicesState || !invoicesState.length && <div className="no-data-container w-100 text-center pt-5" style={{display:'table-caption'}}>
                <span className="no-recent-op">
                  {t("paymentReceiver.noData")}
                </span>
              </div>}
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Pagination
        sx={{
          // marginTop: "10px"
          width:'100%',
          '& ul': { justifyContent:'center' },
        }}
        color="primary"
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        size={screenSize.width <= 576  ? "small" : "medium"}
        count={Math.ceil(invoiceListSelector?.result?.pagination?.pages * 10 / invoiceListSelector?.result?.pagination?.pageSize)} // pagesize is rows per page
        page={invoiceListSelector?.result?.pagination?.currentPage}
        onChange={handlePageChange}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            component="div"
            sx={{
              fontFamily: 'Montserrat',
              borderRadius: '6px !important',
              '&:hover:not(:active)': { backgroundColor: '#006FF4', color: '#FFFFFF' },
              "&.Mui-selected": {
                backgroundColor: "#006FF4",
                color: "white",
                borderColor: "transparent",
                borderRadius: "6px"
              }

            }}
            slots={{
              previous: () => screenSize.width <= 768
                ? <GrFormPrevious width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.previous")}</p>,
              next: () => screenSize.width <= 768
                ? <GrFormNext width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.next")}</p>,
            }}
          />
        )}
      />
        </Modal.Footer>
      </Modal>
    </div>
    </>
  );

};

export default Invoice;
