import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/shared/Footer";
import HamburgerMenu from "../../components/shared/HamburgerMenu";
import LeftSideBar from "../../components/shared/LeftSidebar";
import RightSideBar from "../../components/shared/RightSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import "./index.css";
import InterestAccountCard from "../../components/shared/InterestAccountCard";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import editChartsIcon from '../../assets/images/edit-charts.png'
import { ActivityChart } from "../../components/shared/ActivityChart";
import { LineChartSimple } from "./components/chart/Chart";
import DailyTransactions from '../../assets/images/cardAssets/dailyTransactions.svg'
import DailyRevenue from '../../assets/images/cardAssets/dailyRevenue.svg'
import MonthlyRevenue from '../../assets/images/cardAssets/monthlyRevenue.svg'
import Payouts from '../../assets/images/cardAssets/payouts.svg'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line, ResponsiveContainer } from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { report } from "process";
import { checkIfDataIsFetched, getMonthlyRevenue, getPaginatedTransactions, getGraphData, getStatsData } from "../../redux/toolkit/cardPayments/operation";
import MUIDataTable from "mui-datatables";
import TableHead from "@material-ui/core/TableHead"
import { withStyles } from "@material-ui/core/styles";
import { marginRight } from "styled-system";
import { Spinner, TabContent } from "react-bootstrap";
import TableCell from "@material-ui/core/TableCell";
import MerchantDropdown from "../../components/shared/MerchantDropdown";
import { blue } from "@mui/material/colors";
import MuiTooltip from '@mui/material/Tooltip';
import { useMediaQuery } from '@mui/material';
import axios from "axios";




import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { apiHelper } from "../../api helper";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useScreenSize } from "../../components/shared/ResizeHook";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const currencySymbolMap = require("currency-symbol-map");




const monthAbbreviations: { [key: string]: string } = {
  January: "January",
  February: "Februray",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December"
};


// Function to generate a random color
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};



const renderLegend = (props: any) => {
  const { payload } = props;
  return (
    <ul className="legend-ul">
      {payload.map((entry: any, index: any) => (
        <li key={`item-${index}`} style={{ marginRight: '10px', display: 'flex', alignItems: 'center', fontSize: "14px" }}>
          <div
            style={{
              width: '16px',
              height: '16px',
              backgroundColor: entry.color,
              marginRight: '5px',
              borderRadius: "2px"
            }}
          />
          <span style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};

const CardPayments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [reports, setReports] = useState<any>([]);
  const [cards, setCards] = useState<any>([]);
  const [lineChartData, setLineChartData] = useState<any>([]);
  const [currency, setCurrency] = useState("")
  const [currencies, setCurrencies] = useState([''])
  const [lastYearcurrencies, setlastYearcurrencies] = useState([''])
  const [tableData, setTableData] = useState<any>([]);
  const [totalMonthlyRevenue, setTotalMonthlyRevenue] = useState<any>({});
  const [totalDailyRevenue, setTotalDailyRevenue] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [dailyTransactions, setDailyTransactions] = useState(0);
  const [isdatafetched, setIsdatafetched] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(useSelector((state: any) => state.selectedMerchant.data));
  const [key, setKey] = useState(0);
  const selectedMerchantFromState = useSelector((state: any) => state.selectedMerchant.data);
  const [tickFontSize, setTickFontSize] = useState('');
  const [innerWidth, setInnerWidth] = useState<number>(0);
  const [newDailyTransactionCount, setnewDailyTransactionCount] = useState('');
  const [newDailyTransactionPercantageChange, setnewDailyTransactionPercantageChange] = useState('');
  const [newDailyRevenue, setnewDailyRevenue] = useState('');
  const [newDailyRevenuePercantageChange, setnewDailyRevenuePercantageChange] = useState('');
  const [newMonthlyRevenue, setnewMonthlyRevenue] = useState('');
  const [newMonthlyRevenuePercantageChange, setnewMonthlyRevenuePercantageChange] = useState('');
  const [loaderForGraph, setloaderForGraph] = useState(true);
  const [loaderForCards, setloaderForCards] = useState(true);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [showTooltipMonthly, setShowTooltipMonthly] = useState(false);
  const [showTooltipDaily, setShowTooltipDaily] = useState(false);
  const [monthlyCardCurrency, setMonthlyCardCurrency] = useState('DKK');
  const [dailyCardCurrency, setDailyCardCurrency] = useState('DKK');
  const [transactionsCardCurrency, setTransactionsCardCurrency] = useState('DKK');
  const [cardMontlhySelectedCurrency, setcardMontlhySelectedCurrency] = useState("");
  const [cardDailySelectedCurrency, setcardhDailySelectedCurrency] = useState("");
  const [cardTransactionsSelectedCurrency, setcardTrasnsactionsSelectedCurrency] = useState("");
  const [latestChangeForEachDayTransaction, setlatestChangeForEachDayTransaction] = useState("");
  
  const [merchants, setMerchants] = useState<any[]>([]);
  const [newSelectedMerchant, setNewSelectedMerchant] = useState<any[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const token: any = localStorage.getItem("token");
  const screenSize = useScreenSize();

  useEffect(() => {
    const fetchMerchants = async () => {
      try {

        const userData: any = localStorage.getItem('AccountData')
        const parsedData = JSON.parse(userData);
        const userId = parsedData.userID;
        const response = await apiHelper(
          `shift4cards/getMerchantsByUser?userId=${userId}`,
          'post',
          {},
          {
            Authorization: `Bearer ${token}`
          }
        );


        setMerchants(response.data.data);


        setNewSelectedMerchant(response.data.data[0].merchantId)
      } catch (error) {
        console.error('Error fetching merchants:', error);
      }
    };

    fetchMerchants();
  }, []);

  useEffect(() => {

    if (merchants.length === 0) {
      setNewSelectedMerchant(['']);
      setloaderForCards(false)
      setloaderForGraph(false)
      setLoading(false)
    } else {
      setNewSelectedMerchant(merchants[0].merchantId);
    }
  }, [merchants])



  type TransactionsData = {
    [key: string]: number;
  };

  type PercentageChange = {
    [key: string]: string;
  };

  const [dailyTransactionsData, setDailyTransactionsData] = useState<TransactionsData>({});
  const [dailyTransactionsPercentageChange, setDailyTransactionsPercentageChange] = useState<PercentageChange>({});

  const getCurrencyValue = (data: any, currency: any) => {
    const currencyArray = data.split(' | ');
    const currencyObject = currencyArray.reduce((acc: any, curr: any) => {
      const [currCode, value] = curr.split(' ');
      const formattedValue = parseFloat(value).toLocaleString();
      acc[currCode] = `${currCode} ${formattedValue}`;
      return acc;
    }, {});

    return currencyObject[currency] || '0';
  };


  const handleToggleMontlyTooltip = () => {
    if (isMobile) {
      setShowTooltipMonthly(!showTooltipMonthly);
    }
  };
  const handleToggleDailyTooltip = () => {
    if (isMobile) {
      setShowTooltipDaily(!showTooltipDaily);
    }
  };



  const [cardCurrency, setCardCurrency] = useState({ monthly: 'DKK', daily: 'DKK', transactions: 'DKK' });

  const handleCurrencyChange = (cardType: any, newCurrency: any) => {
    console.log("cardType", cardType);
    console.log("newCurrency", newCurrency);

    if (cardType === "monthly") {
      setcardMontlhySelectedCurrency(newCurrency)
    } else if (cardType === "daily") {
      setcardhDailySelectedCurrency(newCurrency)
    } else {
      setcardTrasnsactionsSelectedCurrency(newCurrency)
    }
    // setCardSelectedCurrency(newCurrency)
    setCardCurrency(prevState => ({
      ...prevState,
      [cardType]: newCurrency,
    }));
  };



  const monthAbbreviationsFull: { [key: string]: string } = {
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December"
  };

  const monthAbbreviationsShort: { [key: string]: string } = {
    January: "Jan",
    February: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    August: "Aug",
    September: "Sep",
    October: "Oct",
    November: "Nov",
    December: "Dec"
  };

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  const isMobile = useMediaQuery('(max-width:576px)');
  const xAxisPadding = isMobile ? 30 : 70;

  const [monthAbbreviations, setMonthAbbreviations] = useState(monthAbbreviationsFull);



  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [recordsFound, setRecordsFound] = useState(true);


  const rowsPerPage = 10;

  const handleResize = () => {
    setTickFontSize(window.innerWidth < 576 ? '8px' : '12px');
    setInnerWidth(window.innerWidth)
    setMonthAbbreviations(window.innerWidth < 576 ? monthAbbreviationsShort : monthAbbreviationsFull)
  };


  useEffect(() => {
    setPage(1)

  }, [selectedMerchantFromState, newSelectedMerchant])

  useEffect(() => {


    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  // console.log("selectedMerchantFromState in card payments", selectedMerchantFromState);
  // console.log("selectedMerchantFromState in card payments from normal state", selectedMerchant);



  useEffect(() => {

    const checkDataStatus = async () => {

      if (typeof newSelectedMerchant === 'string') {
        const payload = {
          merchantId: newSelectedMerchant
        }

        const res: any = await dispatch(checkIfDataIsFetched(payload))
        if (res.payload?.isFetched) {
          setIsdatafetched(true)
        } else {
          setIsdatafetched(false)
        }
      }
    }

    checkDataStatus()

  }, [dispatch, selectedMerchant, selectedMerchantFromState, newSelectedMerchant]);

  const reportsTemplate = {
    title: 'Gross Volume',
    titlePercentage: '3.5%',
    view: 'view',
    viewLink: 'https://appstaging.onemoneyway.com/',
    volume1: "4113.99",
    volume2: "$1253.92"
  }
  const cardData = [
    {
      icon: MonthlyRevenue,
      title: 'Monthly Revenue',
      price: '592,323.65',
      price2: '31,74.23'

    },
    {
      icon: DailyRevenue,
      title: 'Daily Revenue',
      price: '592,323.65',
      price2: '31,74.23'

    },
    {
      icon: Payouts,
      title: 'Payouts',
      price: '592,323.65',
      price2: '31,74.23'

    },
    {
      icon: DailyTransactions,
      title: 'Daily Transactions',
      price: '592,323.65',
      price2: '31,74.23'

    }
  ]
  const lineChardDummyData = [
    {
      name: 'January',
      "This Month": 500,
      "Last Month": 300,
    },
    {
      name: 'February',
      "This Month": 4500,
      "Last Month": 10000,
    },
    {
      name: 'March',
      "This Month": 2000,
      "Last Month": 4000,
    },
    {
      name: 'April',
      "This Month": 17000,
      "Last Month": 10000,
    },
    {
      name: 'May',
      "This Month": 1000,
      "Last Month": 500,
    },
    {
      name: 'June',
      "This Month": 19000,
      "Last Month": 10000,
    },
    {
      name: 'July',
      "This Month": 15000,
      "Last Month": 2000,
    },
    {
      name: 'August',
      "This Month": 25000,
      "Last Month": 30000,
    },
    {
      name: 'Sep',
      "This Month": 18000,
      "Last Month": 5000,
    },
    {
      name: 'October',
      "This Month": 27000,
      "Last Month": 20000,
    },
    {
      name: 'November',
      "This Month": 24000,
      "Last Month": 1000,
    },
    {
      name: 'Dec',
      "This Month": 15000,
      "Last Month": 24000,
    },
  ];

  const staticLineChartData = [
    { name: '0', DKK: 0, EUR: 0, GBP: 0 },
    { name: window.innerWidth < 576 ? "Jan" : 'January', DKK: 0, EUR: 0, GBP: 0 },
    { name: window.innerWidth < 576 ? 'Feb' : 'February', DKK: 0, EUR: 0, GBP: 0 },
    { name: window.innerWidth < 576 ? 'Mar' : 'March', DKK: 0, EUR: 0, GBP: 0 },
    { name: window.innerWidth < 576 ? 'Apr' : 'April', DKK: 0, EUR: 0, GBP: 0 },
    { name: window.innerWidth < 576 ? 'May' : 'May', DKK: 0, EUR: 0, GBP: 0 },
    { name: window.innerWidth < 576 ? 'Jun' : 'June', DKK: 0, EUR: 0, GBP: 0 },
    { name: window.innerWidth < 576 ? 'Jul' : 'July', DKK: 0, EUR: 0, GBP: 0 },
    { name: window.innerWidth < 576 ? 'Aug' : 'August', DKK: 0, EUR: 0, GBP: 0 },
    { name: window.innerWidth < 576 ? 'Sep' : 'September', DKK: 0, EUR: 0, GBP: 0 },
    { name: window.innerWidth < 576 ? 'Oct' : 'October', DKK: 0, EUR: 0, GBP: 0 },
    { name: window.innerWidth < 576 ? 'Nov' : 'November', DKK: 0, EUR: 0, GBP: 0 },
    { name: window.innerWidth < 576 ? 'Dec' : 'December', DKK: 0, EUR: 0, GBP: 0 }
  ]




  useEffect(() => {

    if (isdatafetched) {
      // getCardData();
    }
    // getCardData()
  }, [dispatch, selectedMerchant, isdatafetched, selectedMerchantFromState, newSelectedMerchant]);

  const getCardData = async () => {

    setLoading(true);
    const currentDate = new Date();
    const dateFrom = new Date(Date.UTC(currentDate.getFullYear(), 0, 1)).toISOString().split('T')[0];
    const dateTo = new Date().toISOString().split('T')[0];

    const data = await dispatch(getMonthlyRevenue({ dateFrom, dateTo, selectedMerchantFromState: newSelectedMerchant }))

    if (data.payload !== undefined) {

      const currencySymbol = currencySymbolMap(
        data.payload.currency
      );
      setCurrency(currencySymbol);


      setTotalMonthlyRevenue(data.payload.monthlyTotalAmountForEachCurrency)

      // console.log("data.payload.monthlyTotalAmountForEachCurrency", data.payload.monthlyTotalAmountForEachCurrency);

      // console.log("total monthly revenue", data.payload.monthlyTotalAmountForEachCurrency);
      setTotalDailyRevenue(data.payload.dailyTotalAmountForEachCurrencies)
      setDailyTransactions(data.payload.dailyTransactions)

      // const dailyTransactionPer = 
      const newData = data.payload.monthlyRevenueForEachCurrenciesForCurrentYear;
      // console.log("data.payload.monthlyRevenueForEachCurrenciesForLastYear;",data.payload.monthlyRevenueForEachCurrenciesForCurrentYear);  
      const lastYearData = data.payload.monthlyRevenueByMonthForEachCurrenciesForLastYear



      // Extract all unique currencies
      const currencies: string[] = Array.from(new Set(
        Object.values(newData)
          .flatMap((obj: any) => Object.keys(obj))
      ));

      const lastYearCurrencies: string[] = Array.from(new Set(
        Object.values(newData)
          .flatMap((obj: any) => Object.keys(obj))
      ));

      setCurrencies(currencies)
      setlastYearcurrencies(lastYearCurrencies)

      // Create array of objects with required structure
      const formattedData = Object.entries<Record<string, number>>(newData).map(([month, revenue]) => {
        const monthData: { [key: string]: any } = { name: monthAbbreviations[month] };
        currencies.forEach(currency => {
          monthData[currency] = (revenue as Record<string, number>)[currency] || 0;
        });
        return monthData;
      });

      const lastYearformattedData = Object.entries<Record<string, number>>(lastYearData).map(([month, revenue]) => {
        const monthData: { [key: string]: any } = { name: monthAbbreviations[month] };
        lastYearCurrencies.forEach(currency => {
          monthData[currency] = (revenue as Record<string, number>)[currency] || 0;
        });
        return monthData;
      });

      // console.log("formattedData", formattedData);
      // console.log("lastYearformattedData", lastYearformattedData);

      const combinedData = formattedData.map((item, index) => ({
        name: item.name,
        GBP: item.GBP,
        EUR: item.EUR,
        DKK: item.DKK,
        // USD:item.USD

        // EUR2: lastYearformattedData[index].EUR,
        // GBP2: lastYearformattedData[index].GBP,
      }));

      // console.log("combinedData",combinedData);

      // setLineChartData(combinedData)
      setLoading(false);

      // logig to set values
      cardData[0].price = data.payload.monthlyRevenue;
      cardData[0].price2 = data.payload.monthlyChange;
      cardData[1].price = data.payload.dailyRevenue;
      cardData[1].price2 = data.payload.dailyChange;
      cardData[3].price = data.payload.dailyTransactions;
      cardData[3].price2 = data.payload.dailyTransactionsPercentageDiffrence;
      cardData[2].price = "0"
      lineChardDummyData.forEach(monthData => {
        const monthName = monthData.name;
        monthData["This Month"] = data.payload[monthName] || 0; // Set value from monthlyRevenue object or default to 0
      });

      // console.log("CARD DATA", cardData);
      cardData.splice(2, 1)

      const reportsArray = new Array(1).fill(reportsTemplate);
      setReports(reportsArray)
      setCards(cardData)

      // console.log("cardData", cardData);
    } else {
      const reportsArray = new Array(1).fill(reportsTemplate);
      setReports(reportsArray)
      // setCards(cardData)
    }
  }

  const getTransactionsForTable = async (merchantId: any, limit: any, skip: any) => {
    try {
      const data: any = await dispatch(getPaginatedTransactions({ merchantId, limit, skip }));
      return data.payload;
    } catch (error) {
      console.error('Error fetching transactions:', error);
      throw error;
    }
  };

  const getGraphDataForModule = async (merchantId: any) => {
    try {
      const data: any = await dispatch(getGraphData({ merchantId }));

      return data.payload.graphData
    } catch (error) {
      console.error('Error fetching transactions:', error);
      throw error;
    }
  };

  function calculateMonthlyChange(monthlyRevenue: any) {

    const now = new Date();
    const currentMonthStr = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
    const previousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const previousMonthStr = `${previousMonth.getFullYear()}-${String(previousMonth.getMonth() + 1).padStart(2, '0')}`;


    const changeObj: any = {};


    const currentMonthRevenue = monthlyRevenue[currentMonthStr] || {};
    const previousMonthRevenue = monthlyRevenue[previousMonthStr] || {};

    // Calculate the change for each currency
    const currencies = new Set([...Object.keys(currentMonthRevenue), ...Object.keys(previousMonthRevenue)]);
    currencies.forEach(currency => {
      const currentRevenue = currentMonthRevenue[currency] || 0;
      const previousRevenue = previousMonthRevenue[currency] || 0;
      const change = currentRevenue - previousRevenue;
      changeObj[currency] = change;
    });

    // Format the change as required
    const changeStr = Object.entries(changeObj).map(([currency, change]) => `${currency} ${change}`).join(" | ");
    return changeStr;
  }

  const calculateDailyRevenueDifference = (dailyRevenue: any) => {
    // Get today's date and yesterday's date in 'YYYY-MM-DD' format
    const today = new Date();
    const todayStr = today.toISOString().split('T')[0];

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayStr = yesterday.toISOString().split('T')[0];

    // Get revenue data for today and yesterday
    const todayRevenue = dailyRevenue[todayStr] || {};
    const yesterdayRevenue = dailyRevenue[yesterdayStr] || {};

    // Calculate the difference for each currency
    const revenueDifference: any = {};
    const allCurrencies = new Set([...Object.keys(todayRevenue), ...Object.keys(yesterdayRevenue)]);

    allCurrencies.forEach(currency => {
      const todayValue = todayRevenue[currency] || 0;
      const yesterdayValue = yesterdayRevenue[currency] || 0;
      revenueDifference[currency] = todayValue - yesterdayValue;
    });

    // Format the result as a string
    const resultString = Object.entries(revenueDifference)
      .map(([currency, value]) => `${currency} ${value}`)
      .join(' | ');

    return resultString || '0';
  };







  const getStatsDataForModule = async (merchantId: any) => {
    try {
      const data: any = await dispatch(getStatsData({ merchantId }));

      return data.payload
    } catch (error) {
      console.error('Error fetching transactions:', error);
      throw error;
    }
  }

  useEffect(() => {
    const fetchTableData = async () => {
      setLoading(true);
      try {
        const data = await getTransactionsForTable(newSelectedMerchant, rowsPerPage, page * rowsPerPage);
        // console.log("datata", data);


        setTableData(data.transactions);
        setTotalCount(data.totalCount);
        console.log("totalCount");

        // if(!data.transactions){
        //   setNoRecordFound(true)
        // }

        // console.log("seet table data",tableData);
      } catch (error) {
        console.error('Error fetching paginated transactions:', error);

      } finally {
        setLoading(false);
      }
    };

    if (isdatafetched) {
      fetchTableData();
    }
  }, [page, selectedMerchantFromState, isdatafetched, newSelectedMerchant]);


  useEffect(() => {
    const fetchGraphData = async () => {
      setloaderForGraph(true);
      try {
        const data = await getGraphDataForModule(newSelectedMerchant);
        console.log("my new line chart data", data);

      const currentYear: number = new Date().getFullYear();
      console.log("Current Year:", currentYear);

      const yearData = data[currentYear];
      if (!yearData) {
        console.warn(`No data available for the year ${currentYear}`);
        return;
      }

      const currencies: string[] = Array.from(
        new Set(
          Object.values(yearData)
            .flatMap((monthData: any) => Object.keys(monthData))
        )
      );

      setCurrencies(currencies);

      const formattedData = Object.entries<Record<string, number>>(yearData).map(
        ([month, revenue]) => {
          const monthData: { [key: string]: any } = {
            name: monthAbbreviations[month] || month, 
          };

          currencies.forEach(currency => {
            monthData[currency] = (revenue as Record<string, number>)[currency] || 0;
          });

          return monthData;
        }
      );

      
      setLineChartData([{ name: '0', DKK: 0, EUR: 0, GBP: 0 }, ...formattedData]);






        // console.log("seet table data",tableData);
      } catch (error) {
        console.error('Error fetching paginated transactions:', error);
      } finally {
        setloaderForGraph(false);
      }
    };

    if (isdatafetched) {
      fetchGraphData();
    }
  }, [selectedMerchantFromState, isdatafetched, newSelectedMerchant]);



 


  useEffect(() => {
    const fetchStatsdata = async () => {
      setloaderForCards(true);
      try {
        const data = await getStatsDataForModule(newSelectedMerchant);


        const today = new Date().toISOString().split('T')[0];

        if (data.dailyCountForEachCurrencies) {
          const dailyTransactions = data.dailyCountForEachCurrencies[today] || {};
          setDailyTransactionsData(dailyTransactions);


          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          const yesterdayStr = yesterday.toISOString().split('T')[0];

          const yesterdayTransactions = data.dailyCountForEachCurrencies[yesterdayStr] || {};



          const percentageChange: PercentageChange = {};
          Object.keys(dailyTransactions).forEach(currency => {
            const todayCount = dailyTransactions[currency] || 0;
            const yesterdayCount = yesterdayTransactions[currency] || 0;
            const change = yesterdayCount ? todayCount - yesterdayCount : todayCount;
            percentageChange[currency] = change;
          });


          // console.log("percentageChange",percentageChange);

          const result = `${Object.keys(percentageChange)[0]} ${Object.values(percentageChange)[0]}`


          setlatestChangeForEachDayTransaction(result)

          setDailyTransactionsPercentageChange(percentageChange);
        } else {
          setDailyTransactionsData({});
          setDailyTransactionsPercentageChange({});
        }




        const monthlyChangeForEachCurreny = calculateMonthlyChange(data.monthlyAndDailyRevenue.monthlyRevenue)
        // const dailyChangeForEachCurreny = calculateMonthlyChange(data.monthlyAndDailyRevenue.dailyRevenue)
        const dailyChangeForEachCurreny = calculateDailyRevenueDifference(data.monthlyAndDailyRevenue.dailyRevenue)
        console.log("monthlyChangeForEachCurreny", monthlyChangeForEachCurreny);
        setnewMonthlyRevenuePercantageChange(monthlyChangeForEachCurreny)
        setnewDailyRevenuePercantageChange(dailyChangeForEachCurreny)

        console.log("newDailyRevenuePercantageChange", newDailyRevenuePercantageChange);


        setnewDailyTransactionCount(data.dailyCount)
        setnewDailyTransactionPercantageChange(data.dailyCountChange)

          ;

        const getDailyRevenueForToday = (dailyRev: any) => {
          const today = new Date().toISOString().split('T')[0];

          if (Object.prototype.hasOwnProperty.call(dailyRev, today)) {
            const revenues = dailyRev[today];
            const revenueStrings = Object.entries(revenues).map(([currency, value]) => `${currency} ${value}`);
            return revenueStrings.join(' | ');
          } else {
            return "0";
          }
        };

        const dailyRevenueForToday = getDailyRevenueForToday(data.monthlyAndDailyRevenue.dailyRevenue);
        setnewDailyRevenue(dailyRevenueForToday)



        const getMonthlyRevenueAndPercentageChange = (monthlyRev: any) => {
          const today = new Date();
          const currentMonth = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}`; // Current month in 'YYYY-MM' format
          const previousMonth = `${today.getFullYear()}-${String(today.getMonth()).padStart(2, '0')}`; // Previous month in 'YYYY-MM' format

          const currentMonthRevenue = monthlyRev[currentMonth] || {};
          const previousMonthRevenue = monthlyRev[previousMonth] || {};

          const formatRevenueString = (revenueObj: any) => {
            const revenueStrings = Object.entries(revenueObj).map(([currency, value]) => `${currency} ${value}`);
            return revenueStrings.join(' | ') || '0';
          };

          const calculateTotalRevenue = (revenueObj: any) => {
            return Object.values(revenueObj).reduce((sum: any, value: any) => sum + value, 0);
          };

          const currentMonthTotal: any = calculateTotalRevenue(currentMonthRevenue);
          const previousMonthTotal: any = calculateTotalRevenue(previousMonthRevenue);

          let percentageChange;
          if (previousMonthTotal === 0 && currentMonthTotal > 0) {
            percentageChange = `${currentMonthTotal}`;
          } else if (currentMonthTotal === 0 && previousMonthTotal > 0) {
            percentageChange = `-${previousMonthTotal}`;
          } else if (previousMonthTotal === 0 && currentMonthTotal === 0) {
            percentageChange = "0";
          } else {
            percentageChange = `${((currentMonthTotal - previousMonthTotal)).toFixed(2)}`;
          }

          const formattedCurrentMonthRevenue = currentMonthTotal === 0 ? "0" : formatRevenueString(currentMonthRevenue);

          return {
            currentMonthRevenue: formattedCurrentMonthRevenue,
            percentageChange
          };
        };
        const result = getMonthlyRevenueAndPercentageChange(data.monthlyAndDailyRevenue.monthlyRevenue);
        setnewMonthlyRevenue(result.currentMonthRevenue)















      } catch (error) {
        console.error('Error fetching paginated transactions:', error);
      } finally {
        setloaderForCards(false);
      }
    };

    if (isdatafetched) {
      fetchStatsdata();
    }
  }, [dispatch, selectedMerchantFromState, isdatafetched, newSelectedMerchant]);




  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const formattDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(undefined, options);
    let dateToShow = ''
    if (formattedDate.includes('at')) {
      const [datePart, timePart] = formattedDate.split('at')
      dateToShow = `${datePart} | ${timePart}`
    } else if (formattedDate.includes(',')) {
      const [datePart, timePart] = formattedDate.split(', ')
      dateToShow = `${datePart} | ${timePart}`
    } else {
      dateToShow = formattedDate;
    }
    return dateToShow
  };

  const columns = [

    {
      name: "transactionAmount",
      label: t("cardPayments.amount"),
      options: {
        sort: false, customHeadRender: (columnMeta: any) => (

          <th key={columnMeta.index} className="responsive-header" style={{ paddingLeft: isMobile ? "4px" : "12px" }}>{columnMeta.label}</th>
        ),
        customBodyRender: (value: any) => <TabContent className="responsive-tab-content">{value}</TabContent>
      },
    },
    {
      name: "currency",
      label: t("cardPayments.currency"),
      options: {
        sort: false, customHeadRender: (columnMeta: any) => (

          <th key={columnMeta.index} className="responsive-header" style={{ paddingLeft: isMobile ? "4px" : "12px" }}>{columnMeta.label}</th>
        ),
        customBodyRender: (value: any) => <TabContent className="responsive-tab-content">{value}</TabContent>
      },
    },

    {
      name: "cardScheme",
      label: t("cardPayments.cardType"),
      options: {
        sort: false, customHeadRender: (columnMeta: any) => (

          <th key={columnMeta.index} className="responsive-header" style={{ paddingLeft: isMobile ? "4px" : "12px" }}>{columnMeta.label}</th>
        ),
        customBodyRender: (value: any) => <TabContent className="responsive-tab-content">{value}</TabContent>
      },
    },
    {
      name: "status",
      label: t("cardPayments.status"),
      options: {
        sort: false, customHeadRender: (columnMeta: any) => (
          <th key={columnMeta.index} className="responsive-header" style={{ paddingLeft: isMobile ? "4px" : "12px" }}>{columnMeta.label}</th>
        ),
        customBodyRender: (value: any) => <TabContent className="responsive-tab-content">{value === "Completed Successfully" ? t("cardPayments.success") : value}</TabContent>
      },

    },
    {
      name: "transactionTime",
      label: t("cardPayments.date"),
      options: {
        sort: false, customHeadRender: (columnMeta: any) => (
          <th key={columnMeta.index} className="responsive-header" style={{ paddingLeft: isMobile ? "4px" : "12px" }}>{columnMeta.label}</th>
        )
        ,
        customBodyRender: (value: any) => <TabContent className="responsive-tab-content" style={{ whiteSpace: "nowrap" }} >{formattDate(value)}</TabContent>
      }
    }
  ];

  const CustomPagination = (props: { count: number, rowsPerPage: number, page: number, onChangePage: (newPage: number) => void }) => {
    const { count, rowsPerPage, page, onChangePage } = props;

    const handlePageChange = async (event: React.ChangeEvent<unknown>, newPage: number) => {

      onChangePage(newPage);
    };


    return (
      <Pagination
        sx={{ marginTop: "5px", '& ul': { justifyContent: 'center' } }}
        color="primary"
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        size={isMobile ? "small" : "medium"}
        count={Math.ceil(totalCount / rowsPerPage)}
        page={page}
        onChange={handlePageChange}
        renderItem={(item) => (

          <PaginationItem
            {...item}
            component="div"
            sx={{
              fontFamily: 'Montserrat',
              borderRadius: "6px !important",
              '&:hover:not(:active)': { backgroundColor: '#006FF4', color: '#FFFFFF' },
              "&.Mui-selected": {
                backgroundColor: "#006FF4",
                color: "white",
                borderColor: "transparent",
                borderRadius: "6px"
              }
            }}
            slots={{
              previous: () => screenSize.width <= 768
                ? <GrFormPrevious width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.previous")}</p>,
              next: () => screenSize.width <= 768
                ? <GrFormNext width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.next")}</p>,
            }}
          />
        )}
      />
    );
  };


  const getColor = (percentageChange: any, cardType: any) => {
    console.log(percentageChange, "#### test log");
    const newPercentageChange = percentageChange?.split("|");
    let newPercentageChangeValue = ""
    if (cardType === "monthly") {
      newPercentageChangeValue = newPercentageChange?.find((entry: any) => entry?.includes(cardMontlhySelectedCurrency));
    } else if (cardType === "daily") {

      newPercentageChangeValue = newPercentageChange?.find((entry: any) => entry?.includes(cardDailySelectedCurrency));
    } else {
      newPercentageChangeValue = newPercentageChange?.find((entry: any) => entry?.includes(cardTransactionsSelectedCurrency));

    }

    let newValue: any = ""
    if (newPercentageChangeValue == undefined || newPercentageChangeValue == 'undefined undefined') {
      newValue = "0"

    } else if (newPercentageChangeValue === "0") {
      newValue = "0"
    } else if (newPercentageChangeValue === "") {
      newValue = "0"
    } else {
      newValue = newPercentageChangeValue?.match(/(?:\w+\s*)([+-]?\d+)/)?.[1];
    }




    // if (newValue === "0" || newValue) {
    //   return '#0000FF'; // Blue
    // } else if (newValue?.includes('-')) {
    //   return '#FF0000'; // Red
    // } else {
    //   return '#05D401'; // Green
    // }
    if (newValue?.includes('-')) {
      return '#FF0000'; // Red
    } else if (newValue === "0") {
      return '#0000FF'; // Blue
    } else {
      return '#05D401'; // Green
    }
  };
  const getMargin = (percentageChange: any) => {
    if (percentageChange === "0") {
      return '-7px'; // Blue
    } else if (percentageChange.includes('-')) {
      return '-6px'; // Red
    } else {
      return '-5px !important'; // Green
    }
  };

  const getIcon = (percentageChange: any, cardType: any) => {

    const newPercentageChange = percentageChange?.split("|");
    let newPercentageChangeValue = ""
    if (cardType === "monthly") {
      newPercentageChangeValue = newPercentageChange?.find((entry: any) => entry?.includes(cardMontlhySelectedCurrency));
    } else if (cardType === "daily") {

      newPercentageChangeValue = newPercentageChange?.find((entry: any) => entry?.includes(cardDailySelectedCurrency));
    } else {
      newPercentageChangeValue = newPercentageChange?.find((entry: any) => entry?.includes(cardTransactionsSelectedCurrency));

    }
    let newValue: any = ""
    if (newPercentageChangeValue == undefined || newPercentageChangeValue == 'undefined undefined') {
      newValue = "0"

    } else if (newPercentageChangeValue === "") {
      newValue = "0"
    } else if (newPercentageChangeValue === "0") {
      newValue = "0"
    } else {
      newValue = newPercentageChangeValue?.match(/(?:\w+\s*)([+-]?\d+)/)?.[1];
    }
    // console.log(newPercentageChangeValue , "=====> tayyab curr");
    // console.log(newValue,"new value log");

    if (newValue === "0") {
      return <ArrowRight style={{ marginRight: "-4px", color: "blue" }} />; // Blue
    } else if (newValue?.includes('-')) {
      return <ArrowDropDownIcon style={{ marginRight: "-4px" }} />; // Red
    } else {
      return <ArrowDropUpIcon style={{ marginRight: "-4px", color: "#05d401" }} /> // Green
    }

  };



  const removeMinus = (value: string): string => {
    return value.startsWith('-') ? value.substring(1) : value;
  };



  const xAxisTicks = isMobile ? monthNamesShort : monthNames;


  const getMaxValue = (data: any) => {
    let maxValue = 0;
    data.forEach((entry: { [x: string]: number; }) => {
      Object.keys(entry).forEach(key => {
        if (key !== 'name' && entry[key] > maxValue) {
          maxValue = entry[key];
        }
      });
    });
    return maxValue;
  };

  const getUpperBound = (maxValue: any) => {
    return Math.ceil(maxValue / 5000) * 5000;
  };

  const generateTicks = (upperBound: any) => {
    const ticks = [];
    for (let i = 0; i <= upperBound; i += 5000) {
      ticks.push(i);
    }
    return ticks;
  };

  const maxValue = getMaxValue(lineChartData);
  const upperBound = getUpperBound(maxValue);
  const yAxisTicks = generateTicks(upperBound);
  const yAxisStaticTicks = [0, 5000, 10000, 15000, 20000]

  const handleMerchantSelection = async (merchant: any) => {
    setNewSelectedMerchant(merchant.merchantId);
    setIsDropdownOpen(false);
  };


  const selectedMerchantName = merchants?.length > 0
    ? merchants.find((merchant: any) => merchant.merchantId === newSelectedMerchant)?.merchantName || "Choose Merchant"
    : "Choose Merchant";

  return (
    <>
      <div className="page-container">
        <div>
          <div className="merchantDiv">
            {/* <p className="merchantText">Please Select Your Merchant:</p> */}
            {/* <MerchantDropdown /> */}
            <div style={{ borderRadius: "24px",width: "100%" }}>
              <div>
                <div style={{ cursor: "pointer" }} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                  <div className="lang" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: "#006ff4" }}>
                    <p style={{ color: "#fff", paddingLeft: "10px" }}>
                      {selectedMerchantName}
                    </p>
                    <div className="dropdownOpen" style={{ top: "4px" }}>
                      {isDropdownOpen ? <BsChevronUp color="#fff" /> : <BsChevronDown color="#fff" />}
                    </div>
                  </div>
                </div>

                {isDropdownOpen && (
                  <div className="" 
                  style={{ 
                    width: "100%",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "10px",
                    marginTop: "5px",
                    position: "absolute",
                    zIndex: "9999999",
                    boxShadow: "0 0 6px 2px #001b3a0f"
                    }}>
                    <Form.Group className="langDropDownList" controlId="merchantSelection">
                      {merchants?.length > 0 && merchants.map((merchant: any, index: number) => (
                        <Form.Check
                          type="radio"
                          key={index}
                          id={`merchant_${index}`}
                          value={merchant.merchantId}
                          name="merchant"
                          checked={newSelectedMerchant === merchant.merchantId}
                          onChange={() => handleMerchantSelection(merchant)}
                          label={merchant.merchantName}
                        />
                      ))}
                    </Form.Group>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>


        {merchants.length === 0 ? (
          <div style={{ marginTop: '40px' }}>
            {t("cardPayments.noMerchant")}
          </div>
        ) : (
          !isdatafetched && (
            <div style={{ marginTop: '40px' }}>
              {t("cardPayments.noMerchantData")}
            </div>
          )
        )}





        <div className="row">
          <div className="card-box">

            {loaderForCards ? (<div className="text-center">
              <Spinner animation="border" role="status">
              </Spinner>
            </div>) : (<div className="p-0" style={{ marginTop: '9px' }}>
              <Row className="cards-row">
                <Col xs={6} sm={6} md={6} lg={3} className="cards-column p-2">
                  <div className="card-container">
                    <div className="card-general-mainn">
                      <div className="card-inner">
                        <div className="card-mainbox">
                          <div className="card-mainbox-icon">
                            <img src={MonthlyRevenue} alt="avatar" style={{ width: '24px' }} />
                          </div>
                          <div className="currency-buttons">
                            <button onClick={() => handleCurrencyChange('monthly', 'GBP')} className={cardCurrency.monthly === 'GBP' ? 'active' : 'btn-disable'}>{currencySymbolMap("GBP")}</button>
                            <button onClick={() => handleCurrencyChange('monthly', 'EUR')} className={cardCurrency.monthly === 'EUR' ? 'active' : 'btn-disable'}>{currencySymbolMap("EUR")}</button>
                            <button onClick={() => handleCurrencyChange('monthly', 'DKK')} className={cardCurrency.monthly === 'DKK' ? 'active' : 'btn-disable'}>{currencySymbolMap("DKK")}</button>
                          </div>
                        </div>
                        <div className="text-content-card-general1" style={{ paddingBottom: isMobile ? "0px" : "18px" }}>
                          <span className="card-title">{t("cardPayments.monthlyRevenue")}</span>
                          <span className="card-balance">{isdatafetched ? getCurrencyValue(newMonthlyRevenue, cardCurrency.monthly) : <span style={{ color: "black" }}>0</span>}</span>

                          <p className="card-indicator mb-0" style={{ color: getColor(newMonthlyRevenuePercantageChange, "monthly"), marginLeft: "-6px" }}>
                            {isdatafetched ? (
                              <>
                                {getIcon(newMonthlyRevenuePercantageChange, 'monthly')}
                                {removeMinus(getCurrencyValue(newMonthlyRevenuePercantageChange, cardCurrency.monthly))}
                              </>
                            ) : (
                              <span style={{ color: "blue", marginLeft: "9px" }}>0</span>
                            )}

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs={6} sm={6} md={6} lg={3} className="cards-column p-2">
                  <div className="card-container">
                    <div className="card-general-mainn">
                      <div className="card-inner">
                        <div className="card-mainbox">
                          <div className="card-mainbox-icon">
                            <img src={DailyRevenue} alt="avatar" style={{ width: '24px' }} />
                          </div>
                          <div className="currency-buttons">
                            <button onClick={() => handleCurrencyChange('daily', 'GBP')} className={cardCurrency.daily === 'GBP' ? 'active' : 'btn-disable'}>{currencySymbolMap("GBP")}</button>
                            <button onClick={() => handleCurrencyChange('daily', 'EUR')} className={cardCurrency.daily === 'EUR' ? 'active' : 'btn-disable'}>{currencySymbolMap("EUR")}</button>
                            <button onClick={() => handleCurrencyChange('daily', 'DKK')} className={cardCurrency.daily === 'DKK' ? 'active' : 'btn-disable'}>{currencySymbolMap("DKK")}</button>
                          </div>
                        </div>
                        <div className="text-content-card-general1" style={{ paddingBottom: isMobile ? "0px" : "18px" }}>
                          <span className="card-title">{t("cardPayments.dailyRevenue")}</span>
                          <span className="card-balance">{isdatafetched ? getCurrencyValue(newDailyRevenue, cardCurrency.daily) : <span style={{ color: "black" }}>0</span>}</span>
                          <p className="card-indicator mb-0" style={{ color: getColor(newDailyRevenuePercantageChange, "daily"), marginLeft: "-6px" }}>



                            {isdatafetched ? (
                              <>
                                {getIcon(newDailyRevenuePercantageChange, 'daily')}
                                {removeMinus(getCurrencyValue(newDailyRevenuePercantageChange, cardCurrency.daily))}
                              </>
                            ) : (
                              <span style={{ color: "blue", marginLeft: "9px" }}>0</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs={6} sm={6} md={6} lg={3} className="cards-column p-2">
                  <div className="card-container">
                    <div className="card-general-mainn">
                      <div className="card-inner">
                        <div className="card-mainbox">
                          <div className="card-mainbox-icon">
                            <img src={DailyTransactions} alt="avatar" style={{ width: '24px' }} />
                          </div>
                          <div className="currency-buttons">
                            <button onClick={() => handleCurrencyChange('transactions', 'GBP')} className={cardCurrency.transactions === 'GBP' ? 'active' : 'btn-disable'}>{currencySymbolMap("GBP")}</button>
                            <button onClick={() => handleCurrencyChange('transactions', 'EUR')} className={cardCurrency.transactions === 'EUR' ? 'active' : 'btn-disable'}>{currencySymbolMap("EUR")}</button>
                            <button onClick={() => handleCurrencyChange('transactions', 'DKK')} className={cardCurrency.transactions === 'DKK' ? 'active' : 'btn-disable'}>{currencySymbolMap("DKK")}</button>
                          </div>
                        </div>
                        <div className="text-content-card-general1" style={{ paddingBottom: isMobile ? "0px" : "18px" }}>
                          <span className="card-title">{t("cardPayments.dailyTransactions")}</span>
                          <span className="card-balance">{isdatafetched && dailyTransactionsData[cardCurrency.transactions] ? dailyTransactionsData[cardCurrency.transactions] : 0}</span>
                          {/* <p className="card-indicator mb-0" style={{ color: getColor(dailyTransactionsPercentageChange[cardCurrency.transactions]), marginLeft: getMargin(dailyTransactionsPercentageChange[cardCurrency.transactions]) }}>
                              {getIcon(dailyTransactionsPercentageChange[cardCurrency.transactions])}
                              {removeMinus(dailyTransactionsPercentageChange[cardCurrency.transactions])}%
                            </p> */}
                          <p className="card-indicator mb-0" style={{ color: getColor(latestChangeForEachDayTransaction, "transactions"), marginLeft: "-6px" }}>
                            {isdatafetched ? (
                              <>
                                {getIcon(latestChangeForEachDayTransaction, "transactions")}
                                {dailyTransactionsPercentageChange[cardCurrency.transactions] ? dailyTransactionsPercentageChange[cardCurrency.transactions] : "0"}
                              </>
                            ) : (
                              <span style={{ color: "blue", marginLeft: "9px" }}>0</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>
            </div>)}





            {/* boxShadow: "0px 0px 6px 2px #001B3A0F" */}
            <div className="chart-main-container" style={{ marginTop: "10px", backgroundColor: "white", borderRadius: "10px", height: "480px", marginBottom: "25px", paddingLeft: "0px", paddingRight: "15px" }}>
              <Row className={`${innerWidth < 576 ? "ps-3" : 'ps-4'}`}><span className="responsive-font">{t("cardPayments.monthlyOverview")}</span>
                {/* <Legend verticalAlign="top" align="right"  type="square" style={{marginBottom:"20px"}}/> */}
              </Row>
              <Row className="line-chart-container p-0">
                {loaderForGraph ? (
                  <div className="text-center">
                    <Spinner animation="border" role="status">
                    </Spinner>
                  </div>
                ) : (
                  <Col lg={12} className={`${innerWidth < 576 ? "ps-2 pe-0" : 'responsive-container-column'}`} >
                    <div style={{ height: "400px" }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={isdatafetched ? lineChartData : staticLineChartData} margin={{ left: innerWidth < 576 ? 0 : 15, right: 35, top: innerWidth < 576 ? 0 : -25 }}>
                          <CartesianGrid horizontal={false} vertical={true} />
                          <XAxis
                            stroke="#191919"
                            tick={{ fontSize: tickFontSize }}
                            ticks={xAxisTicks}
                            dataKey="name"
                            // padding={{ left: xAxisPadding }} 
                            axisLine={{ stroke: '#DEE2E6' }}
                            interval={0}
                            dy={innerWidth < 576 ? 5 : 10}
                            tickLine={false}
                          />
                          <YAxis
                            stroke="#191919"
                            tick={{ fontSize: tickFontSize, }}
                            ticks={isdatafetched ? yAxisTicks : yAxisStaticTicks}
                            tickFormatter={(tick) => tick.toLocaleString()}
                            axisLine={{ stroke: '#DEE2E6' }}
                            padding={{ bottom: 20 }}
                            dx={innerWidth < 576 ? -5 : -10}
                            tickLine={false}
                          />
                          <Tooltip />
                          <Legend verticalAlign="top" align="right" type="square" style={{ marginBottom: "20px" }} content={renderLegend} />
                          {Object.keys(lineChartData[0] || {}).filter(key => key !== 'name').map(key => (
                            <Line key={key} type="monotone" strokeWidth={2} dataKey={key} dot={false} name={key} stroke={
                              key === 'GBP' ? '#006FF4' :
                                key === 'EUR' ? '#39B0FA' :
                                  key === 'DKK' ? '#ADB6C0' :
                                    getRandomColor()} />
                          ))}
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </Col>
                )}
              </Row>
              {/* <div style={{ flex: 1, height: '0.5px', backgroundColor: '#E3E8EE', marginTop: "10px", marginBottom: '20px' }} /> */}
            </div>
            {/* boxShadow: "0px 0px 2px 4px #001B3A0F" */}
            <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
              <Row className={`${innerWidth < 576 ? "ps-3 mb-1" : "ps-4 mb-1"}`} >
                {/* <h4 style={{ fontWeight: 600, marginTop: '10px', fontFamily: "Montserrat", fontSize: "24px" }}>Recent Transactions</h4> */}
                <span className="responsive-font">{t("cardPayments.recentTransactions")}</span>
              </Row>
              <Row className={`${innerWidth < 576 ? "ps-2" : "ps-3"}`}>
                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" role="status">
                    </Spinner>
                  </div>
                ) : (
                  <div className="mui-datatable-container">
                    {merchants.length === 0 ? (
                      <div style={{ padding: "20px", textAlign: "center" }}>
                        {t("cardPayments.noMerchantAdded")}
                      </div>
                    ) : !isdatafetched ? (
                      <div style={{ padding: "20px", textAlign: "center" }}>
                        {t("cardPayments.dataNotFetch")}
                      </div>
                    ) : (
                      <MUIDataTable
                        title={""}
                        columns={columns}
                        data={tableData}
                        options={{
                          search: false,
                          download: false,
                          filter: false,
                          print: false,
                          viewColumns: false,
                          selectableRows: "none",
                          responsive: "standard",
                          count: totalCount,
                          rowsPerPage: rowsPerPage,
                          page: page,
                          onChangePage: (newPage) => setPage(newPage),
                          serverSide: true,
                          textLabels: {
                            body: {
                              noMatch: t("cardPayments.merchanthasNoRecord"),
                            },
                          },
                          customFooter: (
                            count,
                            page,
                            rowsPerPage,
                            changeRowsPerPage,
                            changePage
                          ) => (
                            <CustomPagination
                              count={totalCount}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              onChangePage={changePage}
                            />
                          ),
                        }}
                      />
                    )}
                  </div>



                )}
              </Row>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default CardPayments;

