import React,{useState,useEffect} from "react";
import { Modal } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./index.css";

const ModalHeader = ({title,onClose,styleClass,modalBodyRef}:any) => {
  const [hasShadow, setHasShadow] = useState(false);

  useEffect(() => {
    // console.log('modalBodyRef.current:', modalBodyRef?.current); 
    const handleScroll = () => {
      console.log('scrollTop:', modalBodyRef.current.scrollTop); 
      if (modalBodyRef && modalBodyRef.current) {
        setHasShadow(modalBodyRef.current.scrollTop > 0);
      }
    };

    if (modalBodyRef && modalBodyRef.current) {
      modalBodyRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (modalBodyRef && modalBodyRef.current) {
        modalBodyRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [modalBodyRef]);
  

  return (
    <Modal.Header className={`modalHeader ${hasShadow ? "withShadow" : ""} ${styleClass}`}>
      <div className="headerDiv">
        <Modal.Title>{title}</Modal.Title>
      </div>
      <IconButton className="modal-cross-icon"
        style={{
          backgroundColor: "#006ff4",
          color: "white",
          width: "22px",
          height: "22px",
          marginRight:"7px"
        }}
        onClick={onClose}
      >
        <CloseIcon style={{ fontSize: "1.2rem" }} />
      </IconButton>
    </Modal.Header>
  );
};

export default ModalHeader;
