import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
// import AvatarImg from "../../../assets/images/user-img.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { GetALlbeneficiaries,GetALlbeneficiariesPaginated } from "../../../redux/toolkit/beneficiaries/operation";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
// import PlusImage from "../../../assets/images/pluss.png";
import { useNavigate } from "react-router-dom";
import PaymentCardProps from "./paymentCardProps";
import { Col, Modal, Row, Table ,Button} from "react-bootstrap";
import { PlusIcon, UserAvatar } from "../../../constants/Images";

import "./index.css";
import ModalHeader from "../Modal/ModalHeader";
import FundsTransferForm from "../../../pages/SendFunds/FundsTransferForm";

const PaymentReceiverCardNew = (props: PaymentCardProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const beneficiariesData: any = useSelector((state: RootState) => state.beneficiary.data);
  const [beneficiariesState, setBeneficiaries] = useState<[]>([]);
  const [modalData, setModalData] = useState<any>("");
  const [accountId, setAccountId] = useState<any>(localStorage.getItem("accountId"));
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [showSendMoney, setShowSendMoney] = useState(false);
  const [beneficiaryData,setBeneficiaryData] = useState("");
  const [show2FA, setShow2FA] = useState(false);
  const navigate = useNavigate();
  const sendMoneyModalRef = useRef(null);
  const sessionEmail = localStorage.getItem('session_email');
  const roles: any = JSON.parse(localStorage.getItem("roles") || "[]");
  const reqPayload ={
    accountId,
    page:1,
    limit:3,
    search:''
  }

  useEffect(() => {
    // dispatch(GetALlbeneficiaries(accountId));
    getData()

    setLoader(true);
  }, [dispatch, accountId, loader, show]);

  useEffect(() => {
    if (beneficiariesData) {
      setBeneficiaries(beneficiariesData?.data?.data);
      setLoader(false);
    }
  }, [beneficiariesData, beneficiariesState, loader]);

  useEffect(() => {
    document.addEventListener("CardValue", (event: any) => {
      const { detail } = event;
      setAccountId(detail.accountId);
    });
  }, []);

  const eyeClick = (data: {
    name: string;
    account: string;
    address: object;
  }) => {
    setShow(true);
    setModalData(data);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShowSendMoney(true);
  }

  const handleCloseModal = () => {
    setShowSendMoney(false);
  }
  const handleClick = (ele:any) => {
    handleShow()
    setBeneficiaryData(ele)
  }  
  const getData =async () => {
    await dispatch(GetALlbeneficiariesPaginated(reqPayload));
  };

  return (
    <>
    {!roles || roles?.length === 0 || !roles?.includes('ACCOUNTANT') || (roles?.includes('ACCOUNTANT') && sessionEmail) ? (
      <Col  xs={1} sm={1} md={1} lg={1} className="px-1" style={{margin:"0px"}} onClick={() => navigate("/addbeneficiary")}>
        <div className="border-dashed-card" style={{height:"100%",marginRight:"5px"}}>
          <img className="dash-img" src={PlusIcon} alt="box-dashed" />
        </div>
      </Col>
    ): null}
     <Col xs={11} sm={11} md={11} lg={11} className="">
     <Row className="">
     {loader ? (
        <Stack spacing={1}>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rectangular" width={210} height={60} />
        </Stack>
      ) : beneficiariesState && beneficiariesState.length > 0 ? (
        beneficiariesState
          .slice(0, 3)
          // .reverse()
          .map(
            (
              ele: { name: string; account: string; address: object },
              index: number
            ) => {
              return (
                <Col xs={4} sm={4} md={4} lg={4} xl={3} key={index} className="payment-card-column-main-new ps-1 pe-1" >
                  <div className="payment-receiver-mainn d-flex justify-content-between gap-lg-3 flex-column p-3">
                    
                  <div className="image-icons">
                    <img
                      src={UserAvatar}
                      alt="avatar"
                      className="image-rounded"
                    />
                    <BsThreeDotsVertical
                      className="three-dot-icon m-0"
                      size={30}
                      onClick={() => eyeClick(ele)}
                    />
                  </div>

                  <div className="text-content">
                    <p className="accountNo-text">{ele.name}</p>
                    <p className="name-text-account">{ele.account}</p>
                  </div>
                  <div className="pay-now-btn-container d-flex justify-content-end">
                    <Button variant="outline-primary" size="sm" onClick={()=>handleClick(ele)}>
                      {t("paymentReceiver.button.Paynow")}
                    </Button>
                  </div>
                  </div>
                </Col>
                // <Col  className="m-1" style={{background:'red', height:'175px'}}>adsf</Col>
              );
            }
          )
      ) : (
        <div className="no-data-container">
          <span className="no-recent-op">{t("recipient.noData")}</span>
        </div>
      )}

     </Row>

     </Col>
     {/* <Col sm={12} md={11} lg={11}> */}
     
      {/* </Col> */}

    {/* <div
        className="border-dashed-card col-1 me-3"
        onClick={() => navigate("/addbeneficiary")}
      >
        <div className="plus-img">
          <img className="dash-img" src={PlusIcon} alt="box-dashed" />
        </div>
      </div>
      {loader ? (
        <Stack spacing={1}>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rectangular" width={210} height={60} />
        </Stack>
      ) : beneficiariesState && beneficiariesState.length > 0 ? (
        beneficiariesState
          .slice(-3)
          .reverse()
          .map(
            (
              ele: { name: string; account: string; address: object },
              index: number
            ) => {
              return (
                // <div
                //   key={index}
                //   className="payment-receiver-mainn d-flex justify-content-between flex-column p-2 me-3"
                // >
                //   <div className="image-icons">
                //     <img
                //       src={UserAvatar}
                //       alt="avatar"
                //       className="image-rounded"
                //     />
                //     <BsThreeDotsVertical
                //       className="three-dot-icon"
                //       size={30}
                //       onClick={() => eyeClick(ele)}
                //     />
                //   </div>

                //   <div className="text-content">
                //     <p className="accountNo-text">{ele.name}</p>
                //     <p className="name-text-account">{ele.account}</p>
                //   </div>
                // </div>
                <Col sm={12} md={4} lg={3} key={index} className="m-1" style={{background:'red'}}>adsf</Col>
              );
            }
          )
      ) : (
        <div className="no-data-container">
          <span className="no-recent-op">{t("recipient.noData")}</span>
        </div>
      )} */}

      
      {/* </Row> */}
      {/* Recipient Detail Modal */}
      <Modal
        style={{ marginTop: 100 }}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="recipient-detail-modal-root"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>{t("recipient.modal.title")}</Modal.Title>
        </Modal.Header> */}
        <ModalHeader title={t("recipient.modal.title")} onClose={() => setShow(false)}/> 
        <Modal.Body>
          <Table>
            <tbody>
              <tr>
                <td className="detailLabel">{t("recipient.modal.name")}:</td>
                <td>{modalData?.name}</td>
              </tr>
              <tr>
                <td className="detailLabel">{t("recipient.modal.iban")}:</td>
                <td>{modalData?.account}</td>
              </tr>
              <tr>
                <td className="detailLabel">{t("recipient.modal.address")}:</td>
                <td>{modalData?.address?.line_1}</td>
              </tr>
              <tr>
                <td className="detailLabel">{t("recipient.modal.postCode")}:</td>
                <td>{modalData?.address?.postcode}</td>
              </tr>
              <tr>
                <td className="detailLabel">{t("recipient.modal.city")}:</td>
                <td>{modalData?.address?.city}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>


      {/* transfer money modal */}
      <Modal
        show={showSendMoney}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        className={show2FA ? 'd-none' : 'd-block'}
      >
        <ModalHeader title={t("sendFunds.title")} onClose={handleCloseModal} modalBodyRef={sendMoneyModalRef}/> 
        <Modal.Body
        ref={sendMoneyModalRef}
        >
          <FundsTransferForm beneficiaryData={beneficiaryData} setShow2FA={setShow2FA} setShowSendMoney={setShowSendMoney}/>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentReceiverCardNew;
